import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {AuthenticationService} from "../../services/authentication.service";

@Injectable({
    providedIn: "root",
})
export class AdminGuard  {
    constructor(public router: Router,
                private authenticationService:AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Guard for user is login or not
        let user = this.authenticationService.userValue;
        console.log(JSON.stringify(user));
        if (!user) {
            this.router.navigate(["/auth/login"]);
        } else if (user) {
            if (!Object.keys(user).length) {
                this.router.navigate(["/auth/login"]);
            }
        }
        return true;
    }
}
