<label *ngIf="!noLabel" class="form-label" for="validation{{label}}">{{label}}</label>
<div class="input-group">
    <input class="form-control form-control-sm"
           id="validation{{label}}"
           [class.is-invalid]="formControl.invalid"
           [placeholder]="'yyyy-mm-dd'"
           name="dp"
           [formControl]="formControl"
           ngbDatepicker #d3="ngbDatepicker"
           [placement]="'bottom'"
           [required]="required"
           [positionTarget]="buttonEl" />
    <button #buttonEl class="btn btn-success bootstrap-touchspin-up"
            (click)="d3.toggle()" type="button">
        <i class="fa fa-calendar-o"></i>
    </button>
    <div class="invalid-feedback"  *ngIf="requiredValue">
        <span class="badge badge-danger">{{ formControl.invalid ? invalid() : requiredValue}}</span>
    </div>
</div>
<!--<div class="m-1" *ngIf="notes"><span class="badge badge-light-success">{{notes}}</span></div>-->

