import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxDatatableCardBody} from "../../../components/common/ngx-datatable-card-body/ngx-datatable-card-body";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Select2} from "../../../components/common/select2-field/select2-field";

@Component({
    templateUrl: "./manage-user-inquiry.page.html",
})
export class ManageUserInquiryPage implements OnInit, AfterViewInit{
    @BlockUI() blockUI: NgBlockUI;
    buttonSearchText = 'Search';
    urlEditor = '/manage/users/editor';
    @ViewChild('dt') dt:NgxDatatableCardBody;
    formGroup: FormGroup = this.fb.group({
        userName: [null],
        loginStatus: [null],
        levelList: [null]
    });
    columns: any=[
        {
            label:"Nama",
            dataName:"full_name"
        },
        {
            label:"Email",
            dataName:"email"
        },
        {
            label:"Outlet",
            dataName:"name_store"
        },
        {
            label:"Level",
            dataName:"level"
        },
        {
            label:"No. Telp",
            dataName:"phone_number"
        },
        {
            label:"Last Login",
            dataName:"last_login"
        },
        {
            label:"Login Status",
            dataName:"login_sts_txt",
            type: 'status',
            trueValue: 'Online'
        },
        {
            label:"Created Date",
            dataName:"created_dt"
        }
    ];
    actionPassword: any={
        class: 'primary',
        icon: "icon-key",
        tooltip: "Update Password"
    }

    constructor(private fb: FormBuilder,
                private router: Router,
                private secureApi: SecureJsonApiService) {
    }

    ngAfterViewInit(): void {
        this.fetchData({offset: 0, limit: 10}).then();

    }

    ngOnInit(): void {
        this.blockUI.start("Loading...");
    }
    get f() { return this.formGroup.controls; }

    search() {
        this.buttonSearchText = 'Searching..';
        this.fetchData({offset: 0, limit: 10}).then();
        this.dt.resetPage();
    }

    reset() {
        this.formGroup.reset();
    }

    async fetchData(event: { offset: any; limit: any; }) {
        this.dt.setLoadingDt(true);
        let payload = new FormData();
        payload.set("search[value]",this.f.userName.value ?? "");
        payload.set("filter[login_status]",this.f.loginStatus.value?.id ?? "");
        payload.set("filter[level]",this.f.levelList.value?.id ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.users,
                payload,
                event
            );
        if (result) {
            result.data.forEach((data: { [x: string]: any; })=>{
                data['login_sts_txt'] = data['login_sts'] == 'ON' ? 'Online':'Offline';
            });
            this.dt.setAllRows(result.data, result.recordsFiltered, result.recordsTotal);
        }
        this.dt.setLoadingDt(false);
        this.buttonSearchText = "Search"
        this.blockUI.stop();
    }

    add() {
        this.router.navigate([this.urlEditor]).then();
    }

    selectedEvent(data:any) {
        this.router.navigate([this.urlEditor, data['sid']]).then();
    }

    async deletedEvent(data: any) {
        let params = new HttpParams();
        params = params.set("id", data['sid']);
        await this.secureApi.deleteForm(environment.users.delete, params);
        this.fetchData({offset: 0, limit: 10}).then();
    }

    updatePassword(data: any) {
        this.router.navigate(['/manage/users/update-password', data['sid']]).then();
    }

    loginStatusValue:Select2[]=[
        new Select2("ON","Online", null),
        new Select2("OFF","Offline", null)
    ];

    levelList:Select2[]=[
        new Select2("kasir","Kasir", null),
        new Select2("admin","Admin", null)
    ];
}
