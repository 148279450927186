import {Component, EventEmitter, Input, Output} from "@angular/core";
import {NavService} from "../../../shared/services/nav.service";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";

/**
 * @description
 * TextField Component
 *
 * @Input Mandatory:
 * * label:string
 * * formcontrolName or formcontrol
 * **/
@Component({
    selector: 'text-autocomplete-field',
    templateUrl: './text-autocomplete-field.html',
})
export class TextAutocompleteField{
    @Input() label: string;
    @Input() placeholder: string;
    @Input() readOnly: any=false;
    @Output() outputData = new EventEmitter<any>();

    public text: string;
    public searchResult: boolean = false;
    public searchResultEmpty: boolean = false;
    public dataOption: any[];

    constructor(private secureApi:SecureJsonApiService) {
    }

    async searchTerm(term: any) {
        if(!term){
            this.searchResult = false;
            return;
        }

        this.searchResult = true;
        this.dataOption = [];
        let payload = new FormData();
        payload.set("search[name]", term ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.product,
                payload,
                { offset: 0, limit: 10 }
            );
        if (result?.data?.length > 0) {
            this.searchResultEmpty = false;
            this.dataOption.push(...result.data);
        }else{
            this.searchResultEmpty = true;
        }
    }

    onClik(data) {
        this.text = null;
        this.searchResult = false;
        this.outputData.emit(data);
    }
}
