import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxDatatableCardBody} from "../../../components/common/ngx-datatable-card-body/ngx-datatable-card-body";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
    templateUrl: "./manage-outlet-inquiry.page.html",
})
export class ManageOutletInquiryPage implements OnInit, AfterViewInit{
    @BlockUI() blockUI: NgBlockUI;
    buttonSearchText = 'Search';
    urlEditor = '/manage/outlet/editor';
    @ViewChild('dt') dt:NgxDatatableCardBody;
    formGroup: FormGroup = this.fb.group({
        outletName: [null]
    });
    columns: any=[
        {
            label:"Nama Outlet",
            dataName:"name_store"
        },
        {
            label:"No. Telp",
            dataName:"nohp"
        },
        {
            label:"Email",
            dataName:"email"
        },
        {
            label:"Alamat",
            dataName:"address"
        },
        {
            label:"Pajak",
            dataName:"tax",
            type:"number"
        },
        {
            label:"Service",
            dataName:"service_charge",
            type:"number"
        },
        {
            label:"Barcode Ext",
            dataName:"enable_barcode_ext"
        },
        {
            label:"Show Barcode",
            dataName:"show_barcode"
        },
        {
            label:"Rounding",
            dataName:"auto_round"
        }
    ];

    constructor(private fb: FormBuilder,
                private router: Router,
                private secureApi: SecureJsonApiService) {
    }

    ngAfterViewInit(): void {
        this.fetchData({offset: 0, limit: 10}).then();
        //this.blockUI.stop();
    }

    ngOnInit(): void {
        this.blockUI.start("Loading...");
    }
    get f() { return this.formGroup.controls; }

    search() {
        this.fetchData({offset: 0, limit: 10}).then();
        this.dt.resetPage();

    }

    reset() {
        this.formGroup.reset();
    }

    async fetchData(event: { offset: any; limit: any; }) {
        this.buttonSearchText = 'Searching..';
        this.dt.setLoadingDt(true);
        let payload = new FormData();
        payload.set("search[value]",this.f.outletName.value ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.storeList,
                payload,
                event
            );
        if (result) {
            this.dt.setAllRows(result.data, result.recordsFiltered, result.recordsTotal);
        }
        this.dt.setLoadingDt(false);
        this.buttonSearchText = "Search"
        this.blockUI.stop();
    }

    add() {
        this.router.navigate([this.urlEditor]).then();
    }

    selectedEvent(data:any) {
        this.router.navigate([this.urlEditor, data['id_store']]).then();
    }

    async deletedEvent(data: any) {
        let params = new HttpParams();
        params = params.set("id", data['id_store']);
        await this.secureApi.deleteForm(environment.store.delete, params);
        this.fetchData({offset: 0, limit: 10}).then();
    }
}
