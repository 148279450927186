<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo"  routerLink='/'>
                            <img class="img-fluid for-light" src="assets/images/logo/dgpos.png" alt="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
                            [formGroup]="formGroup">
                            <h4>Registrasi</h4>
                            <p>Silahkan isi form dibwah ini untuk membuat akun dgPOS PRO</p>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <!--Outlet ID <span class="f-light"><span class="badge badge-primary">Outlet ID akan digunakan untuk login.</span>
                                        </span>-->
                                        <text-field
                                                label="Outlet ID"
                                                formControlName="outletId"
                                                placeholder="ex : DGSHOP"
                                                required="true"
                                                (ngModelChange)="capitalizeInput()"
                                                minlength="3"
                                                maxlength="10"
                                                [pattern]="'^[A-Z0-9]*$'"
                                                requiredValue="Required, Min 3, Maximum 10 Character"
                                        ></text-field>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <span class="badge badge-primary">Outlet ID akan digunakan untuk login.</span>
                                        <span class="badge badge-secondary">Contoh : DGSHOP</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <text-field
                                                label="Nama Outlet"
                                                formControlName="outletName"
                                                required="true"
                                                minlength="1"
                                                maxlength="50"
                                                [pattern]="'^[a-zA-Z0-9 ]*$'"
                                                requiredValue="Required, alphabet and number only, Maximum 50 Character"
                                        ></text-field>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <text-field
                                                label="Nama Pemilik"
                                                formControlName="owner"
                                                required="true"
                                                [pattern]="'^[a-zA-Z ]*$'"
                                                minlength="1"
                                                maxlength="50"
                                                requiredValue="Required, alphabet only,
                                                Maximum 50 Character"
                                        ></text-field>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <text-field
                                                label="No Telepon"
                                                formControlName="phoneNumber"
                                                type="text"
                                                placeholder="Format : 08xxxxxx"
                                                required="true"
                                                [pattern]="'^[0-9 ]*$'"
                                                minlength="1"
                                                maxlength="15"
                                                requiredValue="Required, number only,
                                                Maximum 15 Character"
                                        ></text-field>
                                    </div>
                                    <div class="col-6 mb-3">
                                        <text-field
                                                label="Email"
                                                formControlName="email"
                                                type="email"
                                                required="true"
                                                maxlength="50"
                                                requiredValue="Required, maximum 50 character, email format only"
                                        ></text-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <textarea-field
                                                label="Alamat"
                                                required="true"
                                                maxlength="100"
                                                requiredValue="Required, maximum 100 character"
                                                formControlName="address">
                                        </textarea-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <select2-field
                                                label="Bisnis Kategori"
                                                formControlName="bizcat"
                                                parameter="bizcat"
                                                [required]="true"
                                        >
                                        </select2-field>
                                    </div>
                                    <div class="col-6 mb-3">
                                        <select2-field
                                                label="Provinsi"
                                                formControlName="province"
                                                [url]="urlSelect2Provinsi"
                                                [field]="fieldSelect2Provinsi"
                                                placeholder="Pilih data provinsi"
                                                [required]="true"
                                        >
                                        </select2-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <text-field
                                                label="Password"
                                                formControlName="password"
                                                type="password"
                                                required="true"
                                                minlength="8"
                                                requiredValue="Required, minimum 8 character"
                                        ></text-field>
                                    </div>
                                    <div class="col-6 mb-3">
                                        <text-field
                                                label="Re-Type Password"
                                                formControlName="retypePassword"
                                                type="password"
                                                (blur)="retypePassword()"
                                                required="true"
                                                minlength="8"
                                                requiredValue="Required, minimum 8 character"
                                        ></text-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <text-field
                                                label="Referral Code"
                                                maxlength="15"
                                                formControlName="referralCode"
                                        ></text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <button class="btn btn-outline-primary d-block w-100"
                                        (click)="register()"
                                        type="button">Daftar</button>
                            </div>
                            <p class="mt-1 mb-0">Kamu sudah mempunyain akun? Silahkan <a class="ms-1" [routerLink]="'/auth/login'">Log in</a></p>
                            <p class="mt-1 mb-0">Link verifikasi email expired?<a class="ms-2" [routerLink]="'/auth/login'">KIRIM ULANG</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
