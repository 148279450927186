import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {NgxImageCompressService, UploadResponse} from "ngx-image-compress";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * @description
 * UploadImageCompress Component
 *
 * @Input Mandatory:
 * * label:string
 * * formcontrolName or formcontrol
 * **/
@Component({
    selector: 'upload-image-compress',
    templateUrl: './upload-image-compress.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UploadImageCompress {
    @ViewChild('content') content:NgbModal;
    @Input() label: string;
    @Input() srcUrl: string;
    @Output() imageChangedEvent = new EventEmitter();
    fileName:string;
    valid=false;
    constructor(private imageCompress: NgxImageCompressService,
                private modalService: NgbModal,) {
    }

    pushPhoto(url:string){
        if(!url){
            this.valid = false;
            return;
        }

        this.srcUrl = url;
        let splitUrl = url.split("/");
        this.fileName = splitUrl[splitUrl.length-1];
        this.valid = this.fileName!=null;
    }

    async upload() {
        this.imageCompress
            .uploadFile()
            .then(({ image, fileName }: UploadResponse) => {

                if (!this.isValidFileType(fileName)) {
                    this.fileName = "Only JPEG or PNG files are allowed";
                    this.valid = false;
                    return;
                }

                if(this.imageCompress.byteCount(image) > 2000000){
                    this.fileName = "maximum size 2 MegaBytes";
                    this.valid = false;
                    return;
                }
                this.valid = true;
                this.fileName = fileName;
                this.srcUrl = image;
                const file = this.dataURItoFile(image, this.fileName);
                const fileResult = {
                    filename : this.fileName,
                    valid : this.valid,
                    file : file
                }
                this.imageChangedEvent.emit(fileResult)
                console.log(
                    `Original: ${image.substring(0, 50)}... (${image.length} characters)`
                );
                console.log('Size in bytes was:', this.imageCompress.byteCount(image));
            });
    }

    dataURItoFile(dataURI: string, fileName: string): File {
        // Step 1: Extract base64-encoded data
        const base64Data = dataURI.split(',')[1];

        // Step 2: Convert base64 to binary
        const binaryData = atob(base64Data);

        // Step 3: Create Blob from binary data with the appropriate MIME type
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const blob = new Blob([uint8Array], { type: mimeString });

        // Step 4: Create File from Blob
        const file = new File([blob], fileName, { type: mimeString });

        return file;
    }

    async toBase64 (file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
    }

    showPicture() {
        this.modalService.open(this.content, {size: 'lg'});
    }

    isValidFileType(fileName: string): boolean {
        const allowedTypes = ['image/jpeg', 'image/png'];
        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

        return allowedTypes.indexOf(`image/${fileType}`) !== -1;
    }
}
