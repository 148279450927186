import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import Swal from 'sweetalert2';
import {StoreService} from "../../services/dgpos/store.service";
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  buttonText = 'Login';

  public loginForm: FormGroup;
  public show: boolean = false;

  constructor(private fb: FormBuilder,
              public router: Router,
              private helper:HelperService,
              private storeService:StoreService,
              private authenticationService:AuthenticationService) {
    this.loginForm = this.fb.group({
      outletId: ['SIKASIK', [Validators.required]],
      email: ['owner@sikasik.com', [Validators.required, Validators.email]],
      password: ['1234567', [Validators.required]]
    });
  }

  capitalizeInput() {
    const outletIdControl = this.loginForm.get('outletId');
    if (outletIdControl.value) {
      outletIdControl.setValue(outletIdControl.value.toUpperCase());
    }
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.buttonText = 'Logging in...';

    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService.login(this.f.outletId.value, this.f.email.value, this.f.password.value).subscribe(
        async data => {
          if (data) {
            await this.storeService.getStoreListFromServer().then();
            await this.storeService.getCurrentStore().then();
            this.router.navigate(["/dashboard/default"]).then();
          }
        },error => {
          Swal.fire({
            title: 'Opps..',
            text: 'Email atau Password anda tidak sesuai',
          });
        }
    ).add(() => {
      this.buttonText = 'Login';
    });
  }

  showPassword() {
    this.show = !this.show;
  }
}
