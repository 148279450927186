<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ng-template (attached)="onComponentRendering($event)" [cdkPortalOutlet]="selectedPortal">
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
