import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxDatatableCardBody} from "../../../components/common/ngx-datatable-card-body/ngx-datatable-card-body";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
    templateUrl: "./manage-customer-inquiry.page.html",
})
export class ManageCustomerInquiryPage implements OnInit, AfterViewInit {
    buttonSearchText = 'Search';

    urlEditor = '/manage/customer/editor';
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('dt') dt:NgxDatatableCardBody;
    formGroup: FormGroup = this.fb.group({
        customerName: [null]
    });
    columns: any=[
        {
            label:"Kode Customer",
            dataName:"cust_code"
        },
        {
            label:"Nama Customer",
            dataName:"name_customer"
        },
        {
            label:"Email",
            dataName:"email"
        },
        {
            label:"No. Telepon",
            dataName:"telephone",
        },
        {
            label:"Saldo",
            dataName:"saldo_cust",
            type:"number"
        },
        {
            label:"Created By",
            dataName:"created_by"
        },
        {
            label:"Created Date",
            dataName:"created_dt",
        }
    ];

    constructor(private fb: FormBuilder,
                private router: Router,
                private secureApi: SecureJsonApiService) {
    }
    get f() { return this.formGroup.controls; }

    ngAfterViewInit(): void {
        this.fetchData({offset: 0, limit: 10}).then();
        //this.blockUI.stop();
    }

    ngOnInit(): void {
        this.blockUI.start("Loading...");
    }

    async search() {
        this.buttonSearchText = 'Searching..';
        await this.fetchData({offset: 0, limit: 10}).then();
        this.dt.resetPage();
    }

    reset() {
        this.formGroup.reset();
    }

    async fetchData(event: { offset: any; limit: any; }) {
        let payload = new FormData();
        payload.set("search[value]",this.f.customerName.value ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.customer,
                payload,
                event
            );
        if (result) {
            this.dt.setAllRows(result.data, result.recordsFiltered, result.recordsTotal);
        }
        this.dt.setLoadingDt(false);
        this.buttonSearchText = "Search"
        this.blockUI.stop();

    }

    add() {
        this.router.navigate([this.urlEditor]).then();
    }

    selectedEvent(data:any) {
        this.router.navigate([this.urlEditor, data['id_customer']]).then();
    }

    async deletedEvent(data: any) {
        let params = new HttpParams();
        params = params.set("id", data['id_customer']);
        params = params.set("code", data['cust_code']);
        await this.secureApi.deleteForm(environment.customer.delete, params);
        this.fetchData({offset: 0, limit: 10}).then();
    }
}
