import {RouterModule, Routes} from "@angular/router";
import {DashboardPage} from "./dashboard.page";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {WelcomeComponent} from "./welcome/welcome.component";
import {ProductStatusChartBoxComponent} from "./product-status-chart-box/product-status-chart-box.component";
import {SalesChartComponent} from "./sales-chart/sales-chart.component";
import {NgApexchartsModule} from "ng-apexcharts";
import {SharedCommonModules} from "../../components/common/shared-common.modules";
import {TodaySummaryComponent} from "./today-summary/today-summary.component";

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "default",
                component: DashboardPage,
            },{
                path: "today-summary",
                component: TodaySummaryComponent,
            },
        ],
    },
];
@NgModule({
    declarations: [
        DashboardPage,
        WelcomeComponent,
        ProductStatusChartBoxComponent,
        SalesChartComponent,
        TodaySummaryComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        NgApexchartsModule,
        CommonModule, SharedModule, NgbModule, FormsModule, SharedCommonModules],
    exports: [
        RouterModule,
        DashboardPage
    ]
})
export class DashboardModule {}
