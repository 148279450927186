<app-breadcrumb [title]="'Manage Product'" [items]="['Manage', 'Product']"
                [active_item]="'Edit'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
                          [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-12 mb-3">
                                <text-field
                                        label="Nama Produk / Jasa"
                                        formControlName="productName"
                                        [required]="true">
                                </text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Kode Produk"
                                        formControlName="barcode"
                                >
                                </text-field>
                            </div><div class="col-md-3">
                                <select2-field
                                        label="Kategori"
                                        formControlName="category"
                                        [url]="urlCategorySelect2"
                                        [field]="fieldCategorySelect2"
                                        [required]="true"
                                >
                                </select2-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <select2-field
                                        label="Satuan Produk"
                                        formControlName="pcsProduct"
                                        parameter="produk-weight"
                                        [required]="true"
                                >
                                </select2-field>
                            </div>
                        </div>
                        <div class="row g-3 mb-3 offset-6">
                            <span class="f-light"><span class="badge badge-primary">Khusus Grosir</span>
                                jika produk mempunyai harga grosir bisa diisi, lewati jika tidak ada.</span>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-3">
                                <currency-field
                                        label="Harga Beli"
                                        formControlName="buyPrice"
                                        [required]="true">
                                </currency-field>
                            </div>
                            <div class="col-md-3">
                                <currency-field
                                        label="Harga Jual"
                                        formControlName="sellPrice"
                                        [required]="true">
                                </currency-field>
                            </div>
                            <div class="col-md-3">
                                <text-field
                                        label="Minimal Grosir"
                                        type="number"
                                        [required]="f.wholesalerPrice.value > 0"
                                        formControlName="wholesalerMinimum">
                                </text-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <currency-field
                                        label="Harga Grosir"
                                        [required]="f.wholesalerMinimum.value > 0"
                                        formControlName="wholesalerPrice">
                                </currency-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-6 mb-3">
                                <checkbox-field
                                        label="Produk ini mempunyai stok"
                                        formControlName="isHaveStock">
                                </checkbox-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <upload-image-compress
                                        #uploadPhotoProduct
                                        label="Foto Produk"
                                        (imageChangedEvent)="productPhotoEvent($event)">
                                </upload-image-compress>
                            </div>
                        </div>
                        <div class="row g-3">
                            <textarea-field
                                    label="Informasi Produk"
                                    formControlName="productInfo">
                            </textarea-field>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()"
                                type="button">Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="helper.backNav()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
