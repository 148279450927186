import {NgModule} from "@angular/core";
import {SharedCommonModules} from "../common/shared-common.modules";
import {ProductLookup} from "./product-lookup/product-lookup";
import {SharedModule} from "../../shared/shared.module";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomerLookup} from "./customer-lookup/customer-lookup";


@NgModule({
    declarations:[
        ProductLookup,
        CustomerLookup
    ],
    imports: [
        SharedCommonModules,
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports:[
        ProductLookup,
        CustomerLookup
    ],
    providers:[],
})
export class LookupModalModule {}
