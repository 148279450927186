<app-breadcrumb [title]="'Manage Kategori'" [items]="['Manage', 'Kategori']"
                [active_item]="'Editor'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
 [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Nama Kategori"
                                        placeholder="Masukan Nama Kategory"
                                        formControlName="categoryName"
                                        [required]="true">
                                </text-field>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()" type="button" >Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="cancel()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
