import {AfterViewInit, Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {HelperService} from "../../../../services/helper.service";
import {HttpParams} from "@angular/common/http";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
    templateUrl: "./manage-category-editor.page.html",
})
export class ManageCategoryEditorPage  implements OnInit, AfterViewInit{
    @BlockUI() blockUI: NgBlockUI;
    formGroup: FormGroup = this.fb.group({
        categoryName: [null],
        categoryId: [null]

    });

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                private helper: HelperService,
                private secureApi: SecureJsonApiService) {
    }

    get f() { return this.formGroup.controls; }

    ngOnInit(): void {
        this.blockUI.start("Loading...");
        this.f.categoryId.setValue(this.activatedRoute.snapshot.params['id'] ?? null)
    }

    async ngAfterViewInit() {
        if( this.f.categoryId.value){
            await this.fetchData();
        }
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.categoryId.value)
            return;

        let param = new HttpParams();
        param = param.set('id', this.f.categoryId.value);
        const dataCategory = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.category.list, param) as any;
        if(dataCategory?.data?.length>0){
            this.formGroup.patchValue({
                categoryName : dataCategory['data'][0]['name_category']
            });
        }
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let payload = new FormData();
        let url = environment.category.create;
        payload.set("name_category", this.f.categoryName.value);
        if(this.f.categoryId.value){
            payload.set("id", this.f.categoryId.value);
            payload.set("app_time", this.helper.formatDate(Date()));
            url = environment.category.update;
        }else{
            payload.set("app_date_time", this.helper.formatDate(Date()));
        }
        await this.secureApi.saveDataForm(url, payload);
    }

    update(payload, url){
        payload.set("name_category", this.f.categoryName.value ?? "");
        payload.set("app_date_time", this.helper.formatDate(Date()));
        url = environment.category.create;
    }

    cancel() {
        this.helper.backNav();
    }
}
