import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxDatatableCardBody} from "../../../components/common/ngx-datatable-card-body/ngx-datatable-card-body";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
    templateUrl: "./manage-product-inquiry.page.html",
})
export class ManageProductInquiryPage implements OnInit, AfterViewInit{

    buttonSearchText = 'Search';

    @BlockUI() blockUI: NgBlockUI;
    urlEditor = '/manage/product/editor';
    @ViewChild('dt') dt:NgxDatatableCardBody;
    formGroup: FormGroup = this.fb.group({
        productName: [null],
        category: [null]
    });
    columns: any=[
        {
            label:"Kode Produk",
            dataName:"codeproduct"
        },
        {
            label:"Nama Produk",
            dataName:"name_product"
        },
        {
            label:"Nama Kategori",
            dataName:"name_category"
        },
        {
            label:"Satuan",
            dataName:"prod_weight"
        },
        {
            label:"Harga Beli",
            dataName:"purchase_price",
            type: 'number'
        },
        {
            label:"Harga Jual",
            dataName:"selling_price",
            type: 'number'
        },
        {
            label:"Diskon",
            dataName:"discount",
            type: 'number'
        },
        {
            label:"Stok",
            dataName:"stock",
            type: 'number'
        },
        {
            label:"Harga Grosir",
            dataName:"wholesale_price",
            type: 'number'
        },
        {
            label:"Created Date",
            dataName:"created_dt"
        },
        {
            label:"Last Update",
            dataName:"last_update"
        }
    ];
    urlCategorySelect2: any=environment.category.select2
    fieldCategorySelect2: any= ["id_category","name_category"];

    constructor(private fb: FormBuilder,
                private router: Router,
                private secureApi: SecureJsonApiService) {
    }

    ngAfterViewInit(): void {
        this.fetchData({offset: 0, limit: 10}).then();
        //this.blockUI.stop();
    }

    ngOnInit(): void {
        this.blockUI.start("Loading...");
    }
    get f() { return this.formGroup.controls; }

    search() {
        this.buttonSearchText = 'Searching..';
        this.fetchData({offset: 0, limit: 10}).then();
        this.dt.resetPage();

    }

    reset() {
        this.formGroup.reset();
    }

    async fetchData(event: { offset: any; limit: any; }) {

        this.dt.setLoadingDt(true);
        let payload = new FormData();
        payload.set("search[name]",this.f.productName.value ?? "");
        payload.set("search[category]",this.f.category.value?.text ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.product,
                payload,
                event
            );
        if (result) {
            this.dt.setAllRows(result.data, result.recordsFiltered, result.recordsTotal);

        }
        this.dt.setLoadingDt(false);
        this.buttonSearchText = "Search"
        this.blockUI.stop();
    }

    add() {
        this.router.navigate([this.urlEditor]).then();
    }

    selectedEvent(data:any) {
        this.router.navigate([this.urlEditor, data['id_product']]).then();
    }

    async deletedEvent(data: any) {
        let params = new HttpParams();
        params = params.set("id", data['id_product']);
        await this.secureApi.deleteForm(environment.product.delete, params);
        this.fetchData({offset: 0, limit: 10}).then();
    }
}
