<label class="form-label">{{label}}</label>
<div class="m-t-15 m-checkbox-inline custom-radio-ml">
    <div class="form-check form-check-inline radio radio-primary"
         *ngFor="let radio of radioOption; let i=index">
        <input class="form-check-input"
               [formControl]="formControl"
               [id]="label + i"
               type="radio"
               name="{{label}}"
               [required]="required"
               value="{{radio.value}}" />
        <label class="form-check-label mb-0" [for]="label + i">{{radio.label}}</label>
    </div>
</div>

<!--<div *ngIf="!formControl.dirty && required"><span class="badge badge-danger">{{requiredValue}}</span></div>-->

