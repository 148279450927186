export const overallBalance = {
    series: [
        {
            name: "Pengeluaran",
            data: [803000,762000,390000,752000,488000,207000,259000],
        },{
            name: "Penjualan",
            data: [1803000,1762000,2390000,2752000,1488000,2207000,2259000],
        },
    ],
    chart: {
        type: "line",
        height: 300,
        stacked: false,
        toolbar: {
            show: false,
        },
        dropShadow: {
            enabled: true,
            top: 8,
            left: 0,
            blur: 10,
            color: "primary_color",
            opacity: 0.1,
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "10px",
            borderRadius: 0,
        },
    },
    grid: {
        show: true,
        borderColor: "var(--chart-border)",
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 2,
        dashArray: 0,
        lineCap: "butt",
        curve: "smooth"
    },
    fill: {
        opacity: 1,
    },
    legend: {
        show: false,
    },
    states: {
        hover: {
            filter: {
                type: "darken",
                value: 1,
            },
        },
    },
    colors: ["#fc0505", "#007e08"],
    yaxis: {
        tickAmount: 3,
        labels: {
            show: true,
            style: {
                fontFamily: "Rubik, sans-serif",
            },
            formatter: function(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    xaxis: {
        categories: ["8-Nov","9-Nov","10-Nov","11-Nov","12-Nov","13-Nov","14-Nov"],
        labels: {
            style: {
                fontFamily: "Rubik, sans-serif",
            },
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    responsive: [
        {
            breakpoint: 1661,
            options: {
                chart: {
                    height: 290,
                },
            },
        },
        {
            breakpoint: 767,
            options: {
                plotOptions: {
                    bar: {
                        columnWidth: "35px",
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
            },
        },
        {
            breakpoint: 481,
            options: {
                chart: {
                    height: 200,
                },
            },
        },
        {
            breakpoint: 420,
            options: {
                chart: {
                    height: 170,
                },
                plotOptions: {
                    bar: {
                        columnWidth: "40px",
                    },
                },
            },
        },
    ],
};
