import {AfterViewInit, Component} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HelperService} from "../../../../services/helper.service";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpParams} from "@angular/common/http";
import {StoreService} from "../../../../services/dgpos/store.service";
import {AuthenticationService} from "../../../../services/authentication.service";
import {Select2} from "../../../../components/common/select2-field/select2-field";

@Component({
    templateUrl: "./manage-outlet-editor.page.html",
})
export class ManageOutletEditorPage implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;

    formGroup: FormGroup = this.fb.group({
        outletId : [null],
        outletName : [null],
        email : [null],
        phoneNumber : [null],
        address : [null],
        taxPct : [null],
        ServiceChargePct : [null],
        receiptClosingNote : [null],
        isCopyFromHO : [null],
        isShowingFootNoteInvoice : [null],
        isShowingBarcode : [null],
        isRounding : [null],
        isHideTransaction : [null],
        isEnableBarcodeExt : [null],
        dateFormatRecFin: [null]
    });

    dataExist:any;
    labelCopyFromHO: any;
    isBizCatLaundry:boolean=false;
    dateFormatRecFinSelect2Opt:Select2[]=[
        new Select2("date","Tanggal", null),
        new Select2("datetime","Tanggal & Jam", null)
    ];

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                public helper: HelperService,
                private storeService: StoreService,
                private authSerrvice:AuthenticationService,
                private secureApi: SecureJsonApiService) {
        this.blockUI.start("Loading...");
        this.isBizCatLaundry = this.authSerrvice.userValue.bizcat == 'laundry';
        this.storeService.store.subscribe(store=>{
            this.labelCopyFromHO = 'Copy Data Produk Dari '+store.storeName;
        })
        this.f.outletId.setValue(this.activatedRoute.snapshot.params['id'] ?? null);
    }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchData();
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.outletId.value)
            return;

        let param = new HttpParams();
        param = param.set('id', this.f.outletId.value);
        const dataOutlet = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.store.byId, param) as any;
        if(dataOutlet?.data?.length>0){
            this.dataExist = dataOutlet.data[0];
            this.setData();
        }
    }

    get f() { return this.formGroup.controls; }

    setData(){
        let dateFormatRecFinSelect =  this.dateFormatRecFinSelect2Opt.filter((x)=>x.id==this.dataExist["store_date_format"]);

        this.formGroup.patchValue({
            outletId : this.dataExist["id_store"] ?? null,
            outletName : this.dataExist["name_store"] ?? null,
            email : this.dataExist["email"] ?? null,
            phoneNumber : this.dataExist["nohp"] ?? null,
            address : this.dataExist["address"] ?? null,
            taxPct : this.dataExist["tax"] ?? null,
            ServiceChargePct : this.dataExist["service_charge"] ?? null,
            receiptClosingNote : this.dataExist["footer_notes"] ?? null,
            isShowingFootNoteInvoice : this.dataExist["show_fn"] == 'Y',
            isShowingBarcode : this.dataExist["show_barcode"] == 'Y',
            isRounding : this.dataExist["auto_rounding"] == 'Y',
            isHideTransaction : this.dataExist["hide_trx_sts"] == 'Y',
            isEnableBarcodeExt : this.dataExist["ext_barcode"] == 'Y',
            dateFormatRecFin: dateFormatRecFinSelect[0],
        });
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let url = this.dataExist ? environment.store.update : environment.store.create ;
        let payload = new FormData();

        payload.set("id", this.f.outletId.value ?? null )
        payload.set("name_store", this.f.outletName.value ?? null )
        payload.set("email", this.f.email.value ?? null )
        payload.set("nohp", this.f.phoneNumber.value ?? null )
        payload.set("address", this.f.address.value ?? null )
        payload.set("tax", this.f.taxPct.value ?? null )
        payload.set("service_charge", this.f.ServiceChargePct.value ?? null )
        payload.set("footer_notes", this.f.receiptClosingNote.value ?? null )

        if(!this.dataExist){
            payload.set("copyProd", this.f.isCopyFromHO.value ? '1' :'0' )
        }else{
             payload.set("showFooterNotes", this.f.isShowingFootNoteInvoice.value? 'Y': 'N');
             payload.set("showBarcode", this.f.isShowingBarcode.value? 'Y': 'N');
             payload.set("autoRounding", this.f.isRounding.value? 'Y': 'N');
             payload.set("hideCancelTrx", this.f.isHideTransaction.value? 'Y': 'N');
             payload.set("barcode_box_ext", this.f.isEnableBarcodeExt.value? 'Y': 'N');
            if(this.isBizCatLaundry){
                payload.set("date_format", this.f.dateFormatRecFin.value?.id ?? null )
            }
        }

        await this.secureApi.saveDataForm(url, payload);
    }
}
