import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    forwardRef,
    inject,
    Input,
    OnInit,
} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, noop, Observable, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Select2} from "../select2-field/select2-field";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";


/**
 * @description
 * SelectOption Component
 *
 * @Input Mandatory:
 * * url: string
 * * formcontrolName or formcontrol
 * * field : object with key idField:string, textField:string
 * **/
@Component({
    selector: 'select-option',
    templateUrl: './select-option.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectOption), multi: true}
    ]
})
export class SelectOption implements ControlValueAccessor, OnInit{
    formControl: FormControl = new FormControl<SelectOpt[]>(null);
    items:Observable<SelectOpt[]>;
    dataSelect2 = new BehaviorSubject<SelectOpt[]>([]);

    destroyRef: DestroyRef = inject(DestroyRef);

    onChange: (value: string) => void = noop;
    onTouch: () => void = noop;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() requiredValue: string = "required !!";
    @Input() url: string;
    @Input() field:any={
        idField : "id_category",
        textField: "name_category"
    };

    constructor(private secureApi: SecureJsonApiService,
    ) {
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    writeValue(value: string): void {
        this.formControl.setValue(value, { emitEvent: false });
    }

    ngOnInit(): void {
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
        this.renderSelectData().then();
    }

    async renderSelectData(){
        await this.getSelect2().then()
    }

    async getSelect2(){
        const result = await this.secureApi
            .asyncAwaitRequestParamCommon(
                this.url, new HttpParams()) as any;
        if(result){
            this.dataSelect2.next(this.processingDataToSelect2(result.data));
            this.items = this.dataSelect2.asObservable();
        }
    }

    processingDataToSelect2(dataList:any[]){
        let select2List = [];
        dataList.forEach(data=>{
            const select2Data = new Select2(
                data[this.field.idField],
                data[this.field.textField],
                data
            );
            select2List.push(select2Data);
        });
        return select2List;
    }

}

export class SelectOpt {
    constructor(id: string, text: string, data) {
        this.id = id;
        this.text=text;
        this.data=data;
    }
    id:string;
    text:string;
    data:any;
}
