<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/2.jpg)',
                                     'background-size': 'cover',
                                     'background-position': 'center center',
                                     'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/2.jpg" alt="background login">
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-center" href="#">
              <img class="img-fluid for-light" src="assets/images/logo/dgpos.png" width="164" height="147" alt="dgpos login">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="dgpos login">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate="" [formGroup]="loginForm">
              <div class="form-group">
                <label class="col-form-label">Outlet ID</label>
                <input class="form-control" type="text" required="" formControlName="outletId" (input)="capitalizeInput()">
                <div class="invalid-tooltip" *ngIf="loginForm.controls.outletId.touched && loginForm.controls.outletId.errors?.required">outletId is required.</div>
                <div class="invalid-tooltip" *ngIf="loginForm.controls.outletId.touched && loginForm.controls.outletId.errors?.outletId">Invalid outlet Id.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Email</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email">
                <div class="invalid-tooltip" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Email is required.</div>
                <div class="invalid-tooltip" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">Invalid Email Address.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********" formControlName="password">
                <div class="invalid-tooltip" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div>
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <p class="mt-4 mb-0">
                </p>

                <button class="btn btn-primary d-block w-100" type="button" [disabled]="!loginForm.valid"
                        (click)="login()" >{{ buttonText }}</button>
                <a [routerLink]="'/authentication/forgot-password'" class="ms-2 text-md-right">Lupa password?</a>
              </div>
              <p class="mt-4 mb-0">Belum punya akun?
                <a [routerLink]="'/auth/register'" class="ms-2">Buat Akun.</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
