<div class="translate_wrapper" [class.active]="storeService.storeFlag">
    <div class="current_lang">
        <div class="lang">
            <img src="assets/svg/shop-svgrepo-com.svg" alt=""/>
            <span class="lang-txt">{{selectedStore.storeName}}</span>
        </div>
    </div>
    <div class="more_lang" [class.active]="storeService.storeFlag">
        <div class="lang selected" data-value="en" (click)="changeStore(store)" *ngFor="let store of storeList">
            <img src="assets/svg/shop-svgrepo-com.svg" alt=""/>
            <span class="lang-txt">{{store.storeName}}<span *ngIf="store.desc">({{store.desc}})</span></span>
        </div>
    </div>
</div>
