<app-breadcrumb [title]="'Manage Outlet Editor'" [items]="['Manage', 'Outlet']"
                [active_item]="'Editor'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
                          [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-12 mb-3">
                                <text-field
                                        label="Nama Outlet"
                                        formControlName="outletName"
                                        maxlength="50"
                                        [required]="true">
                                </text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Email"
                                        formControlName="email"
                                        type="email"
                                        maxlength="50"
                                        required="true"
                                >
                                </text-field>
                            </div>
                            <div class="col-md-6 mb-3">
                                <text-field
                                        label="No Telepon"
                                        formControlName="phoneNumber"
                                        type="number"
                                        placeholder="Format : 08xxxxxx"
                                        [pattern]="'^[0-9 ]*$'"
                                        minlength="1"
                                        maxlength="15"
                                ></text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-12 mb-3">
                                <textarea-field
                                        label="Alamat"
                                        formControlName="address"
                                        maxlength="100"
                                        [required]="true">
                                </textarea-field>
                            </div>
                        </div>
                        <div class="row g-3" *ngIf="dataExist && isBizCatLaundry">
                            <div class="col-md-6 mb-3">
                                <select2-field
                                        label="Format Tanggal Terima & Selesai"
                                        [manualValue]="dateFormatRecFinSelect2Opt"
                                        [required] = "dataExist && isBizCatLaundry"
                                        formControlName="dateFormatRecFin">
                                </select2-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <autonumeric-field
                                        label="Pajak"
                                        defaultType="percent"
                                        formControlName="taxPct">
                                </autonumeric-field>
                            </div>
                            <div class="col-md-6 mb-3">
                                <autonumeric-field
                                        label="Biaya Layanan"
                                        defaultType="percent"
                                        formControlName="ServiceChargePct">
                                </autonumeric-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-12 mb-3">
                                <textarea-field
                                        label="Catatan Penutup Struk"
                                        formControlName="receiptClosingNote"
                                        maxlength="1000">
                                </textarea-field>
                            </div>
                        </div>
                        <div class="row g-3" *ngIf="!dataExist">
                            <div class="col-md-6 mb-3">
                                <checkbox-field
                                        [label]="labelCopyFromHO"
                                        formControlName="isCopyFromHO">
                                </checkbox-field>
                            </div>
                        </div>
                        <div class="row g-3" *ngIf="dataExist">
                            <div class="col-md-4">
                                <checkbox-field
                                        label="Tampilkan Footer Notes Invoice"
                                        formControlName="isShowingFootNoteInvoice">
                                </checkbox-field>
                            </div>
                            <div class="col-md-4">
                                <checkbox-field
                                        label="Tampilkan Barcode Ketika Cetak Struk"
                                        formControlName="isShowingBarcode">
                                </checkbox-field>
                            </div>
                            <div class="col-md-4">
                                <checkbox-field
                                        label="Aktifkan Scan Barcode External"
                                        formControlName="isEnableBarcodeExt">
                                </checkbox-field>
                            </div>
                            <div class="col-md-4">
                                <checkbox-field
                                        label="Sembunyikan Transaksi Penjualan & Pengeluaran Cancel"
                                        formControlName="isHideTransaction">
                                </checkbox-field>
                            </div>
                            <div class="col-md-4">
                                <checkbox-field
                                        label="Aktifkan Auto Rounding"
                                        notes="ex: 1.513 jadi 1.500 (-13), 1.575 jadi 1.600 (25)"
                                        formControlName="isRounding">
                                </checkbox-field>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()"
                                type="button">Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="helper.backNav()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
