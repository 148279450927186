import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {formatDate} from "@angular/common";

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                @Inject(LOCALE_ID) public locale: string) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userAuth = this.authenticationService.userValue;
        const isLoggedIn = userAuth?.token;
        const isApiUrl = request.url.startsWith(environment.apiUrlBase);
        if (isLoggedIn && isApiUrl) {
            let headerHttp = new HttpHeaders();
            headerHttp = headerHttp
                .set("DIGIPOS-TIMESTAMP",formatDate(Date(),'yyyy-MM-dd HH:mm:ss', this.locale));
            // headerHttp = headerHttp
            //     .set("DIGIPOS-TIMESTAMP","2023-11-10T21:16:46.289+07:00");
            headerHttp = headerHttp.set("DIGIPOS-VERSION", environment.version);
            headerHttp = headerHttp.set("DIGIPOS-KEY", userAuth.key);
            headerHttp = headerHttp.set("DIGIPOS-DeviceToken", userAuth.dgtl_token);
            headerHttp = headerHttp.set("Authorization", `Bearer ${userAuth.token}`);

            request = request.clone({
                'headers': headerHttp
            });
        }

        return next.handle(request);
    }
}
