import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {HelperService} from "./helper.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import Swal from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    @BlockUI() blockUI: NgBlockUI;
    constructor(private router: Router, private helperService:HelperService){
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap({
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            if(event.status == 200){
                                if(event?.body?.errCode == "00" && (
                                    event?.body?.msg == "Invalid session" ||
                                    event?.body?.msg == "Token expired" ||
                                    event?.body?.msg == "Token revoked" )){
                                    this.blockUI.stop();
                                    this.router.navigate(['/error-page/error-440']).then();
                                }else if(event?.body?.errCode == "02" &&
                                    (event?.body?.msg == "Terdapat kesalahan dalam otorisasi!"||
                                        event?.body?.msg == "Token expired")){
                                    this.blockUI.stop();
                                    this.router.navigate(['/error-page/error-403']).then();
                                }else if(event?.body?.errCode == "02"){
                                    const message = event?.body?.msg ?? "Unknown Error, Contact Your Administration";
                                    this.blockUI.stop();
                                    throw Error(message);
                                }
                            }
                            if(event.status == 401) {
                                //alert('Unauthorized access!')
                                Swal.fire({
                                    title: 'Error',
                                    text: 'Unauthorized Access!',
                                });
                            }
                        }
                        this.blockUI.stop();
                        return event;
                    },
                    error: (error) => {
                        if(error.status === 401) {
                            Swal.fire({
                                title: 'Error',
                                text: 'Unauthorized Access!',
                            });
                            this.router.navigate(['/error-page/error-403']).then();
                        }
                        else if(error.status === 404) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Page Not Found!',
                            });
                            this.router.navigate(['/error-page/error-403']).then();
                        }
                    }
                })
            )
    }
}
