<app-breadcrumb [title]="'Manage Users Editor'" [items]="['Manage', 'Users']"
                [active_item]="'Editor'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
 [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Nama"
                                        formControlName="userName"
                                        maxlength="50"
                                        [required]="true">
                                </text-field>
                            </div>
                            <div class="col-md-3">
                                <text-field
                                        label="Email"
                                        formControlName="email"
                                        type="email"
                                        maxlength="50"
                                        required="true"
                                >
                                </text-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <text-field
                                        label="No Telepon"
                                        formControlName="phoneNumber"
                                        type="number"
                                        placeholder="Format : 08xxxxxx"
                                        [pattern]="'^[0-9 ]*$'"
                                        minlength="1"
                                        maxlength="15"
                                ></text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-12 mb-3">
                                <textarea-field
                                        label="Alamat"
                                        formControlName="address"
                                        maxlength="100">
                                </textarea-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-4">
                                <currency-field
                                        label="Gaji Pokok"
                                        formControlName="salary">
                                </currency-field>
                            </div>
                            <div class="col-md-4">
                                <currency-field
                                        label="Tunjangan"
                                        formControlName="allowance">
                                </currency-field>
                            </div>
                            <div class="col-md-4 mb-3">
                                <currency-field
                                        label="Potongan Gaji"
                                        formControlName="deductible">
                                </currency-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-4">
                                <autonumeric-field
                                        label="Komisi (%)"
                                        defaultType="percent"
                                        formControlName="commission">
                                </autonumeric-field>
                            </div>
                            <div class="col-md-4 mb-3">
                                <currency-field
                                        label="Komisi (Rp)"
                                        formControlName="commissionFix">
                                </currency-field>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select2-field
                                        label="Lokasi Penempatan"
                                        formControlName="storeSelect2"
                                        [url]="urlStoreSelect2"
                                        [field]="fieldStoreSelect2"
                                >
                                </select2-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-4">
                                <radiobutton-field
                                        label="Model Penerapan Komisi"
                                        [radioOption]="commissionModelOption"
                                        formControlName="commissionModel"
                                ></radiobutton-field>
                            </div>
                            <div class="col-md-4">
                                <radiobutton-field
                                        label="Bisa Akses Aplikasi"
                                        [radioOption]="isAccessAppsOption"
                                        formControlName="isAccessApps"
                                ></radiobutton-field>
                            </div>
                            <div class="col-md-4 mb-3">
                                <radiobutton-field
                                        label="Level Akses Aplikasi"
                                        [radioOption]="appLevelAccessOption"
                                        formControlName="appLevelAccess"
                                ></radiobutton-field>
                            </div>
                        </div>
                        <div class="row g-3" *ngIf="!dataExist">
                            <div class="col-6">
                                <text-field
                                        label="Password"
                                        formControlName="password"
                                        type="password"
                                        required="true"
                                        minlength="8"
                                        requiredValue="Required, minimum 8 character"
                                ></text-field>
                            </div>
                            <div class="col-6 mb-3">
                                <text-field
                                        label="Re-Type Password"
                                        formControlName="retypePassword"
                                        type="password"
                                        (blur)="retypePassword()"
                                        required="true"
                                        minlength="8"
                                        requiredValue="Required, minimum 8 character"
                                ></text-field>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()"
                                type="button">Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="helper.backNav()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
