import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HelperService} from "../../../../services/helper.service";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {UploadImageCompress} from "../../../../components/common/upload-image-compress/upload-image-compress";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpParams} from "@angular/common/http";

@Component({
    templateUrl: "./manage-product-editor.page.html",
})
export class ManageProductEditorPage implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('uploadPhotoProduct') uploadPhotoProduct:UploadImageCompress;
    urlCategorySelect2: any=environment.category.select2
    fieldCategorySelect2: any= ["id_category","name_category"];

    formGroup: FormGroup = this.fb.group({
        productId : [null],
        productName : [null],
        barcode : [null],
        category : [null],
        pcsProduct : [null],
        buyPrice : [null],
        sellPrice : [null],
        wholesalerMinimum : [null],
        wholesalerPrice : [null],
        isHaveStock : [null],
        productPhoto : [null],
        productInfo : [null]
    });

    dataExist:any;
    filename:string;

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                public helper: HelperService,
                private router : Router,
                private secureApi: SecureJsonApiService) {
        this.blockUI.start("Loading...");
        this.f.productId.setValue(this.activatedRoute.snapshot.params['id'] ?? null)
    }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchData();
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.productId.value)
            return;

        let param = new HttpParams();
        param = param.set('id', this.f.productId.value);
        const dataProduk = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.product.getProductById, param) as any;
        if(dataProduk?.data?.length>0){
            this.dataExist = dataProduk.data[0];
            this.setData();
        }
    }

    get f() { return this.formGroup.controls; }

    setData(){
        this.formGroup.patchValue({
            productId : this.dataExist["id_product"] ?? null,
            productName : this.dataExist["name_product"] ?? null,
            barcode : this.dataExist["codeproduct"] ?? null,
            category : this.dataExist["id_category"] ?
                this.helper.setSelect2(this.dataExist["id_category"], this.dataExist["name_category"]): null,
            pcsProduct : this.dataExist["prod_weight"] ?
                this.helper.setSelect2(this.dataExist["prod_weight"], this.dataExist["prod_weight"]): null,
            buyPrice : this.dataExist["purchase_price"] ?? null,
            sellPrice : this.dataExist["selling_price"] ?? null,
            wholesalerMinimum : this.dataExist["min_qty_wholesale"] ?? null,
            wholesalerPrice : this.dataExist["wholesale_price"] ?? null,
            isHaveStock : this.dataExist["have_stock"] && this.dataExist["have_stock"] == '1',
            productInfo : this.dataExist["description"] ?? null,
        });
        this.uploadPhotoProduct.pushPhoto(this.dataExist["img"] ?? null);
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        if(!this.f.category.value?.id){
            alert("there is problem")
            return;
        }

        let url = this.dataExist ? environment.product.update : environment.product.create ;
        let payload = new FormData();
        payload.set("id_product", this.f.productId.value ?? null)
        payload.set("name_product", this.f.productName.value ?? null)
        payload.set("codeproduct", this.f.barcode.value ?? null)
        payload.set("id_category", this.f.category.value?.id ?? null)
        payload.set("prod_weight", this.f.pcsProduct.value?.id ?? null)
        payload.set("purchase_price", this.f.buyPrice.value ?? null)
        payload.set("selling_price", this.f.sellPrice.value ?? null)
        payload.set("minimum_purchase", this.f.wholesalerMinimum.value ?? null)
        payload.set("wholesale_price", this.f.wholesalerPrice.value ?? null)
        payload.set("have_stock", this.f.isHaveStock.value?'1':'0')
        payload.set("img", this.f.productPhoto.value ?? null)
        payload.set("description", this.f.productInfo.value ?? null)
        payload.set("app_time", this.helper.formatDate(Date()))
        payload.set("reference", "web")
        await this.secureApi.saveDataForm(url, payload);
    }

    productPhotoEvent(event: any) {
        this.f.productPhoto.setValue(event.file);
        this.filename = event.filename;
    }
}
