<label class="form-label" for="validation{{label}}">{{label}}</label>
<div class="form-group w-100 mb-0 item-search">
    <input class="form-control form-control-sm"
           id="validation{{label}}"
           type="text"
           [placeholder]="placeholder ?? label"
           autocomplete="off"
           [readOnly]="readOnly"
           [(ngModel)]="text" [ngModelOptions]="{ standalone: true }"
           (keyup)="searchTerm(text)"
    />
    <div class="Typeahead-menu" style="top:100%;background-color: white;max-height: 300px;"
         [class.is-open]="searchResult" *ngIf="dataOption?.length" id="search-outer">
        <div class="ProfileCard u-cf" *ngFor="let data of dataOption | slice : 0 : 8"
             (click)="onClik(data)">
            <div class="ProfileCard-details">
                <div class="ProfileCard-realName">
                    <a class="realname">{{data?.["codeproduct"]}} - {{ data?.["name_product"] }} -
                       Stok : {{data?.["stock"]}} - Harga : {{data?.["selling_price"] | number}} </a>
                </div>
            </div>
        </div>
    </div>
    <div class="Typeahead-menu" style="top:100%;background-color: white;max-height: 300px;" [class.is-open]="searchResultEmpty" >
        <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">Opps!! There are no result found.</div>
        </div>
    </div>
</div>
