<label *ngIf="!noLabel" class="form-label" for="validation{{label}}">{{label}}</label>
<ng-select
        class="custom"
        id="validation{{label}}"
        [items]="items | async"
        bindLabel="text"
        [multiple]="multiple"
        placeholder="Select"
        [loading]="loading"
        [formControl]="formControl"
        (change)="onChange($event)"
        [required]="required"
>
</ng-select>
<!--<div class="invalid-feedback" *ngIf="requiredValue">{{requiredValue}}</div>-->
<span *ngIf="!formControl.valid && formControl.touched && requiredValue">
    <span *ngIf = "formControl.errors?.required" style="
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(&#45;&#45;bs-form-invalid-color);">
        <span class="badge badge-danger">{{requiredValue}}</span>
    </span>
</span>
