import {AfterViewInit, Component} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HelperService} from "../../../../services/helper.service";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpParams} from "@angular/common/http";

@Component({
    templateUrl: "./manage-customer-editor.page.html",
})
export class ManageCustomerEditorPage implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;

    formGroup: FormGroup = this.fb.group({
        customerId : [null],
        customerCode : [null],
        customerName : [null],
        email : [null],
        phoneNumber : [null],
        address : [null],
    });

    dataExist:any;
    filename:string;

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                public helper: HelperService,
                private secureApi: SecureJsonApiService) {
        this.blockUI.start("Loading...");
        this.f.customerId.setValue(this.activatedRoute.snapshot.params['id'] ?? null)
    }

    get f() { return this.formGroup.controls; }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchData();
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.customerId.value)
            return;

        let param = new HttpParams();
        param = param.set('cust_id', this.f.customerId.value);
        const dataCustomer = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.customer.byId, param) as any;
        if(dataCustomer?.data?.length>0){
            this.dataExist = dataCustomer.data[0];
            this.setData();
        }
    }

    setData(){
        this.formGroup.patchValue({
            customerId : this.dataExist["id_customer"] ?? null,
            customerCode : this.dataExist["cust_code"] ?? null,
            customerName : this.dataExist["name_customer"] ?? null,
            email : this.dataExist["email"] ?? null,
            phoneNumber : this.dataExist["telephone"] ?? null,
            address : this.dataExist["address"] ?? null,
        });
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let url = this.dataExist ? environment.customer.update : environment.customer.create ;
        let payload = new FormData();
        payload.set("id", this.f.customerId.value ?? null)
        payload.set("code", this.f.customerCode.value ?? null)
        payload.set("name_customer", this.f.customerName.value ?? null)
        payload.set("email", this.f.email.value ?? null)
        payload.set("telephone", this.f.phoneNumber.value ?? null)
        payload.set("address", this.f.address.value ?? null)
        await this.secureApi.saveDataForm(url, payload);
    }
}
