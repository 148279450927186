<label class="form-label" for="validation{{label}}">{{label}}</label>
<input class="form-control"
       id="validation{{label}}"
       type="text"
       currencyMask
       [options]="{ prefix: '', thousands: ',', decimal: '.', precision : '0' }"
       [placeholder]="placeholder ?? label"
       [required]="required"
       [readOnly]="disabled"
       (blur)="onBlurEvent()"
       [formControl]="formControl"/>
<!--<div class="invalid-feedback"><span class="badge badge-danger">{{requiredValue}}</span></div>-->
