import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ManageCustomerInquiryPage} from "./manage-customer/manage-customer-inquiry.page";
import {ManageCustomerEditorPage} from "./manage-customer/editor/manage-customer-editor.page";
import {ManageProductInquiryPage} from "./manage-product/manage-product-inquiry.page";
import {ManageProductEditorPage} from "./manage-product/editor/manage-product-editor.page";
import {SharedCommonModules} from "../../components/common/shared-common.modules";
import {ManageCategoryInquiryPage} from "./manage-category/manage-category-inquiry.page";
import {ManageCategoryEditorPage} from "./manage-category/editor/manage-category-editor.page";
import {ManageSupplierInquiryPage} from "./manage-supplier/manage-supplier-inquiry.page";
import {ManageSupplierEditorPage} from "./manage-supplier/editor/manage-supplier-editor.page";
import {ManageOutletInquiryPage} from "./manage-outlet/manage-outlet-inquiry.page";
import {ManageOutletEditorPage} from "./manage-outlet/editor/manage-outlet-editor.page";
import {ManageUserInquiryPage} from "./manage-user/manage-user-inquiry.page";
import {ManageUserEditorPage} from "./manage-user/editor/manage-user-editor.page";
import {ManageUserUpdatePasswordPage} from "./manage-user/update-password/manage-user-update-password.page";

const routes: Routes = [
    {
        path: "customer",
        children: [
            {
                path: "inquiry",
                component: ManageCustomerInquiryPage,
            },{
                path: "editor",
                component: ManageCustomerEditorPage,
            },{
                path: "editor/:id",
                component: ManageCustomerEditorPage,
            },
        ],
    },{
        path: "product",
        children: [
            {
                path: "inquiry",
                component: ManageProductInquiryPage,
            },{
                path: "editor",
                component: ManageProductEditorPage,
            },{
                path: "editor/:id",
                component: ManageProductEditorPage,
            },
        ],
    },{
        path: "category",
        children: [
            {
                path: "inquiry",
                component: ManageCategoryInquiryPage,
            },{
                path: "editor",
                component: ManageCategoryEditorPage,
            },{
                path: "editor/:id",
                component: ManageCategoryEditorPage,
            },
        ],
    },{
        path: "supplier",
        children: [
            {
                path: "inquiry",
                component: ManageSupplierInquiryPage,
            },{
                path: "editor",
                component: ManageSupplierEditorPage,
            },{
                path: "editor/:id",
                component: ManageSupplierEditorPage,
            },
        ],
    },{
        path: "outlet",
        children: [
            {
                path: "inquiry",
                component: ManageOutletInquiryPage,
            },{
                path: "editor",
                component: ManageOutletEditorPage,
            },{
                path: "editor/:id",
                component: ManageOutletEditorPage,
            },
        ],
    },{
        path: "users",
        children: [
            {
                path: "inquiry",
                component: ManageUserInquiryPage,
            },{
                path: "editor",
                component: ManageUserEditorPage,
            },{
                path: "editor/:id",
                component: ManageUserEditorPage,
            },{
                path: "update-password/:id",
                component: ManageUserUpdatePasswordPage,
            },
        ],
    },
];
@NgModule({
    declarations: [
        ManageCustomerInquiryPage,
        ManageCustomerEditorPage,
        ManageProductInquiryPage,
        ManageProductEditorPage,
        ManageCategoryInquiryPage,
        ManageCategoryEditorPage,
        ManageSupplierInquiryPage,
        ManageSupplierEditorPage,
        ManageOutletInquiryPage,
        ManageOutletEditorPage,
        ManageUserInquiryPage,
        ManageUserEditorPage,
        ManageUserUpdatePasswordPage
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        FormsModule,
        SharedCommonModules,
        ReactiveFormsModule
    ],
    exports: [
        RouterModule
    ]
})
export class ManageModules {}
