<label class="form-label" for="validation{{label}}">{{label}}</label>
<input class="form-control digits"
       id="validation{{label}}"
       #selector
       type='text'
       [placeholder]="placeholder ?? label"
       [required]="required"
       [formControl]="formControl"
       autocomplete="off"/>
<div class="invalid-feedback"><span class="badge badge-danger">{{requiredValue}}</span></div>
