import {Injectable} from "@angular/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpClient, HttpParams} from "@angular/common/http";
import {HelperService} from "./helper.service";
import {FormGroup} from "@angular/forms";
import {environment} from "../../environments/environment";
import {AuthenticationService} from "./authentication.service";

@Injectable({ providedIn: 'root' })
export class SecureJsonApiService{
    @BlockUI() blockUI: NgBlockUI;
    constructor(
        private http:HttpClient,
        private authService:AuthenticationService,
        private helper:HelperService) {
    }

    async asyncAwaitRequest(url: string, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(environment.apiUrlBase+url, payload, {})
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestParam(url: string, paramList: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(environment.apiUrlBase+url, null, {
                params : paramList
            })
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestParamCommon(url: string, params: HttpParams): Promise<any> {
        params = params.set("accesskey",environment.accessKey ?? null);
        params = params.set("key",this.authService?.userValue?.key ?? null);
        params = params.set("login_store_key",this.authService?.userValue?.key ?? null);
        params = params.set("token_user",this.authService?.userValue?.dgtl_token ?? null);
        return new Promise((resolve, reject) => {
            this.http.post<any>(environment.apiUrlBase+url, null, {
                params : params
            })
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestDT(url: string,
                              payload:FormData,
                              offsetLimit: { offset: any; limit: any; }): Promise<any> {
        return new Promise((resolve, reject) => {
            payload.set("key",this.authService.userValue.key);
            payload.set("token_user",this.authService.userValue.dgtl_token);
            payload.set("start",offsetLimit.offset);
            payload.set("length",offsetLimit.limit);
            payload.set("draw","1");
            this.http.post<any>(environment.apiUrlBase+url, payload)
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestSave(url: string,
                              payload:FormData): Promise<any> {
        return new Promise((resolve, reject) => {
            payload.set("accesskey",environment.accessKey);
            payload.set("key",this.authService.userValue.key);
            payload.set("token_user",this.authService.userValue.dgtl_token);
            this.http.post<any>(environment.apiUrlBase+url, payload)
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestParamCommonV2(url: string, paramData ): Promise<any> {
        paramData = paramData.set("key",this.authService.userValue.key);
        paramData = paramData.set("token_user",this.authService.userValue.dgtl_token);
        return new Promise((resolve, reject) => {
            this.http.post<any>(environment.apiUrlBase+url, null, {
                params : paramData
            })
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                this.helper.errorMessagePopUp(err);
                reject(err);
            });
        });
    }

    async asyncAwaitRequestXls(url: string, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${environment.apiUrlBase}${url}`, payload, {responseType:'blob' as 'json'})
                .toPromise().then((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    async saveData(form: FormGroup, url, confirmation?) {
        const confirm = await this.helper.confirmationWarning(confirmation ?? "Are You Sure To Save This Data ?");
        if(!confirm.value)return;

        this.blockUI.start();

        let objectSave = this.getValueFromFormGroup(form);
        const saveData = await this.asyncAwaitRequest(url, objectSave) as any;
        if (saveData != null) {
            this.helper.alertSuccesSaved();
            this.blockUI.stop();
            this.helper.backNav();
        }else{
            this.helper.error();
            this.blockUI.stop();
        }
    }

    async saveDataForm(url: string, payload: FormData, noBack?:boolean) {
        const confirm = await this.helper.confirmationWarning("Konfirmasi",
            "Apakah Anda Yakin Menyimpan Data Ini ? ");
        if (!confirm.value) return;

        this.blockUI.start();
        const result = await this.asyncAwaitRequestSave(
            url,
            payload,
        );
        if (result && result["errCode"] == '01') {
            this.helper.alertSuccesSaved();
            this.blockUI.stop();
            if(!noBack){
                this.helper.backNav();
            }
        } else {
            this.helper.error();
            this.blockUI.stop();
        }
    }

    async deleteForm(url: string, paramData:HttpParams) {
        const confirm = await this.helper.confirmationWarning("Konfirmasi",
            "Apakah Anda Yakin Menghapus Data Ini ? ");
        if (!confirm.value) return;

        this.blockUI.start();
        const result = await this.asyncAwaitRequestParamCommonV2(
            url,
            paramData
        );
        if (result && result["errCode"] == '01') {
            this.helper.alertSuccess("Data Delete Success !!","Data anda berhasil dihapus.");
            this.blockUI.stop();
        } else {
            this.helper.error();
            this.blockUI.stop();
        }
    }

    getValueFromFormGroup(form: FormGroup){
        let objectSave = {};
        Object.keys(form.controls).forEach(key => {
            if(key.indexOf("MultiSelect") !== -1){//MultiSelect
                objectSave[key] = form.controls[key].value ?? null;
            } else if (key.indexOf("Select") !== -1) {//Select
                const _key = key.substring(0,key.length-6);
                objectSave[_key] = form.controls[key].value?.id ?? null;
            } else if(key.indexOf("Dto") !== -1){//Child one on one
                const formChild = form.controls[key] as FormGroup;
                objectSave[key] = this.getValueFromFormGroup(formChild);
            } else if(key.indexOf("CheckBox") !== -1){// CheckBox
                const _key = key.substring(0,key.length-8);
                objectSave[_key] = form.controls[key].value?'1':'0';
            } else if(key.indexOf("Curr") !== -1){// CurrencyType
                const _key = key.substring(0,key.length-4);
                objectSave[_key] = form.controls[key].value.currencyValue ?? null;
            } else {
                objectSave[key] = form.controls[key].value ?? null;
            }
        });
        return objectSave;
    }

    async getDataFromServer(url, request, T) {
        const dataList = await this.asyncAwaitRequest(url, request) as any;
        if (dataList != null) {
            dataList['recordsFiltered'] = this.checkMaxPage(dataList['recordsFiltered'], T.pageSize);
            T.count = dataList['recordsFiltered'];
            T.recordsTotal = dataList.recordsTotal;
            T.rows = [];
            T.rows.push(...dataList.data);
            T.loadingIndicator = false;
        }
    }

    checkMaxPage(recordsTotal, pageSize) {
        const recordsTotalInt : number = parseInt(recordsTotal);
        const maxTotalPage : number = parseInt(localStorage.getItem('maxPageAll'));
        const maxData = maxTotalPage * pageSize;
        if (recordsTotalInt > pageSize) {
            if (recordsTotalInt > maxData) {
                return maxData;
            }
        }

        return recordsTotalInt;
    }
}
