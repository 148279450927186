import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpParams} from "@angular/common/http";
import {SecureJsonApiService} from "../secure-rest.service";
import {HelperService} from "../helper.service";

@Injectable({ providedIn: 'root' })
export class StoreService {
    public storeSubject: BehaviorSubject<any>= new BehaviorSubject(JSON.parse(localStorage.getItem('store')))
    public store: Observable<any> = this.storeSubject.asObservable();
    public storeListSubject: BehaviorSubject<Array<any>> = new BehaviorSubject(JSON.parse(localStorage.getItem('store-list')));
    public storeList: Observable<any[]> = this.storeListSubject.asObservable();
    public storeFlag: boolean = false;

    constructor(private secureApi:SecureJsonApiService,
                private helper:HelperService) {
    }

    async getStoreListFromServer() {
        const url = environment.store.storeList;
        let result = await this.secureApi.asyncAwaitRequestParamCommon(url, new HttpParams()) as any;
        if (result) {
            let xresult = this.processSetStore(result.data);
            localStorage.setItem('store-list',JSON.stringify(xresult));
            // this.storeListSubject.next(xresult);
            // this.storeList = this.storeListSubject.asObservable();
        }
    }

    async getCurrentStore() {
        let getStoreInfo = await this.secureApi
            .asyncAwaitRequestParamCommon(
                environment.store.dashboard,
                new HttpParams()
            );
        if(getStoreInfo?.data?.length > 0){
            let currentStore = this.processSetStore(getStoreInfo.data);
            localStorage.setItem('store',JSON.stringify(currentStore[0]));
            // this.storeSubject.next(currentStore[0]);
            return currentStore;
        }else{
            return null
        }

    }

    processSetStore(dataStore:any[]) {
        let storeDatalist = [];
        dataStore.forEach(store=>{
            let  storeData = {
                storeId: store["id_store"],
                storeName: store["name_store"],
                desc:  store["number_store"],
                data: store
            };
            storeDatalist.push(storeData);
        });
        return storeDatalist;
    }

    async changeStore(store:any) {
        console.log("change store");
        if(store.storeId == this.storeSubject.value.storeId){
            this.helper.errorMessagePopUp("Anda mengganti store yang sama.");
            return;
        }
        const url = environment.store.switchStore;
        let param = new HttpParams();
        param = param.set("id", store.storeId);
        let result = await this.secureApi.asyncAwaitRequestParamCommon(url, param) as any;
        if (result?.errCode == '01') {
            const currStore = await this.getCurrentStore();
            this.helper.errorMessagePopUp(result['msg']);
            // localStorage.setItem('store',JSON.stringify(store));
            this.storeSubject.next(currStore[0]);
        }else{
            this.helper.errorMessagePopUp("Change Store Failed.")
        }
    }
}
