import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    forwardRef,
    inject,
    Input,
    OnInit,
    Optional, Output,
    Self
} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {noop, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import AutoNumeric from "autonumeric";

/**
 * @description
 * DatepickerDurationField Component
 *
 * @Input Mandatory:
 * * label:string
 * * formcontrolName or formcontrol
 * **/
@Component({
    selector: 'datepicker-duration-field',
    templateUrl: './datepicker-duration-field.html',
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerDurationField), multi: true}
    ]
})
export class DatepickerDurationField implements ControlValueAccessor, OnInit{
    formControl: FormControl = new FormControl<DateDuration>(null);

    destroyRef: DestroyRef = inject(DestroyRef);

    onChange: (value: string) => void = noop;
    onTouch: () => void = noop;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() requiredValue: string;
    @Input() notes: string
    @Input() fromDate: NgbDate | null;
    @Input() toDate: NgbDate | null;

    hoveredDate: NgbDate | null = null;

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    writeValue(value: string): void {
        this.formControl.setValue(value, { emitEvent: false });
        this.fromDate = this.validateInput(null,this.formControl.value?.fromDate ?? null);
        this.toDate = this.validateInput(null,this.formControl.value?.toDate ?? null);
    }

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    }

    ngOnInit(): void {
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
        this.formControl.setValue(new DateDuration(
            this.formatter.format(this.fromDate),
            this.formatter.format(this.toDate)));
        this.formControl.updateValueAndValidity();
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate &&
            this.hoveredDate && date.after(this.fromDate) &&
            date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    invalid(){
        this.formControl.setValue(null);
        this.formControl.updateValueAndValidity();
        return "Date Format Invalid !!";
    }
}
export class DateDuration {
    constructor(fromDate: string, toDate: string) {
        this.fromDate=fromDate;
        this.toDate = toDate;
    }
    fromDate:string;
    toDate:string;
}
