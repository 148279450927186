<label class="form-label" for="validation{{label}}">{{label}}</label>
<div class="{{suffixButton?'input-group':''}}">
    <input class="form-control form-control-sm"
           id="validation{{label}}"
           [type]="type"
           [placeholder]="placeholder ?? label"
           [required]="required"
           [maxlength]="maxlength"
           [minlength]="minlength"
           [pattern]="pattern"
           [formControl]="formControl"
           autocomplete="off"
           [readOnly]="readOnly"
           (blur)="onBlurEvent()"
    />
    <button *ngIf="suffixButton"
            class="btn btn-secondary bootstrap-touchspin-up"
            type="button" (click)="suffixClick()">
        <i class="fa {{suffixIcon}}"></i>
    </button>
    <div class="invalid-feedback" *ngIf="requiredValue"><span class="badge badge-danger">{{requiredValue}}</span></div>
</div>

