import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/dgpos/store.service";
import {SecureJsonApiService} from "../../../../../services/secure-rest.service";
import {environment} from "../../../../../../environments/environment";
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'store-list',
    templateUrl: './store-list.component.html'
})
export class StoreListComponent implements OnInit, AfterViewInit{

    public store: boolean = false;

    public storeList: any[] = [{
        storeId: 'xxx',
        storeName: 'Pusat',
        desc: 'HO',
        data:{}
    },{
        storeId: 'vvv',
        storeName: 'Cabang Tangerang',
        desc: null,
        data:{}
    }];

    public selectedStore: any = {
        storeId: 'xxx',
        storeName: 'Pusat',
        desc: 'HO',
        data:{}
    }

    constructor(public storeService:StoreService) { }

    ngOnInit(): void {

    }

    changeStore(store) {
        console.log("change")
        this.storeService.changeStore(store);
    }

    ngAfterViewInit(): void {
        this.storeService.storeList.subscribe(storeList=>{
            if(storeList){
                this.storeList = storeList;
            }
        })

        this.storeService.store.subscribe(storeData=>{
            if(storeData){
                this.selectedStore = storeData;
            }
        })
    }
}
