import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HelperService} from "../../services/helper.service";
import {Md5} from "ts-md5";
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-register',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent {

    public show: boolean = false;
    urlSelect2Provinsi = environment.parameter.province;
    fieldSelect2Provinsi = ["id","province"]
    outletIdPattern="^[A-Z0-9]*$";
    formGroup: FormGroup = this.fb.group({
        outletName : [null],
        outletId : [null],
        owner : [null],
        bizcat : [null],
        phoneNumber : [null],
        email : [null],
        province : [null],
        address : [null],
        password : [null],
        retypePassword : [null],
        referralCode : [null]
    });

    capitalizeInput() {
        const outletIdControl = this.formGroup.get('outletId');
        if (outletIdControl.value) {
            outletIdControl.setValue(outletIdControl.value.toUpperCase(), { emitEvent: false });
        }
    }

    constructor(private fb: FormBuilder,
                private helper:HelperService,
                private authService:AuthenticationService,
                public router: Router) { }
    get f() { return this.formGroup.controls; }


    validate = false;
    async register() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let payload = new FormData();
        const md5 = new Md5();
        const passwordHash = md5.start().appendStr(this.f.password.value).end();
        payload.set("outlet_cd", this.f.outletId.value ?? null)
        payload.set("name_store", this.f.outletName.value ?? null)
        payload.set("email", this.f.email.value ?? null)
        payload.set("full_name", this.f.owner.value ?? null)
        payload.set("phone_number", this.f.phoneNumber.value ?? null)
        payload.set("password", passwordHash.toString())
        payload.set("retype_password", passwordHash.toString())
        payload.set("address", this.f.address.value ?? null)
        payload.set("province", this.f.province.value?.id ?? null)
        payload.set("bizcode", this.f.bizcat.value?.id ?? null)
        payload.set("refcode", this.f.referralCode.value ?? null)
        this.authService.registration(payload).subscribe(
            data=>{
                if(data){
                   this.helper.alertSuccess("Berhasil","Registrasi Akun Anda Telah Berhasil, silahkan login menggunakan email dan password anda")
                    this.router.navigate(["/auth/login"]).then();
                }
            },error => {
                this.helper.errorMessagePopUp(error);
            }
        );
    }

    retypePassword() {
        if(this.f.retypePassword.value != this.f.password.value){
            this.helper.errorMessage("Re-Type Password tidak sama dengan password")
            this.f.retypePassword.setValue(null);
        }
    }
}
