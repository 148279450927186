<label class="form-label" for="validation{{label}}">{{label}}</label>
<select
        class="form-select"
        id="validation{{label}}"
        [formControl]="formControl"
        [required]="required">
    <option value="" selected>Selected Option</option>
    <option value="{{item}}" *ngFor="let item of items | async">{{item.text}}</option>
</select>
<!--<div class="invalid-feedback">{{requiredValue}}</div>-->
