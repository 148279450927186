import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {formatDate, Location} from "@angular/common";

declare let require;
const Swal = require('sweetalert2')

@Injectable({ providedIn: 'root' })
export class HelperService{
    constructor(
        private location:Location,
        private authService:AuthenticationService,
        @Inject(LOCALE_ID) public locale: string) {
    }

    formatDate(date: string){
        return formatDate(date,'yyyy-MM-dd HH:mm:ss', this.locale);
    }

    formatDateOnly(date: string){
        return formatDate(date,'yyyy-MM-dd', this.locale);
    }

    formatDateWithDay(date: string){
        return formatDate(date,'EEEE, d MMM y', this.locale);
    }

    setSelect2(id:string,text:string){
        return {
            "id":id,
            "text":text,
            "data":null
        };
    }

    setMultiSelect2(list:any[], keyId:string, keyValue:string){
        if(!(list && list.length>0))return;
        const multiSelecValuet:any[]=[];
        list.forEach(data=>{
            multiSelecValuet.push(this.setSelect2(data[keyId], data[keyValue]))
        });
        return multiSelecValuet;
    }

    confirmationBackNav(){
        this.confirmationWarning().then((result) => {
            if (result.value) {
                this.backNav();
            }
        })
    }

    backNav(){
        this.location.back();
    }

    confirmationWarning(title?, text?): Promise<any>{
        return Swal.fire({
            title: title ?? 'Are you sure?',
            text: text ?? "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        })
    }

    alertSuccesSaved(){
        Swal.fire(
            'Data Save Success!',
            'Data anda telah di simpan',
            'success'
        );
    }

    alertSuccess(title, message){
        Swal.fire(
            title,
            message,
            'success'
        );
    }

    error() {
        Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ada Sesuatu Yang Terjadi !!!',
            footer: '<a href>Kenapa Aku Mendapatkan Issue Ini ?</a>'
        })
    }

    errorMessage(message) {
        Swal.fire({
            title: 'Oops...',
            text: message
        })
    }

    errorMessagePopUp(message) {
        Swal.fire({
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 5000
        })
    }

    errorMessageLocation() {
        Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: "Aktikan akses lokasi browser kamu dulu, lalu refresh browser",
            footer: 'Cara Aktifkan Lokasi Anda : \n' +
                '<a href="https://support.google.com/chrome/answer/142065?hl=id&co=GENIE.Platform%3DDesktop" target="_blank">' +
                '(Chrome IOS, Android, Desktop)</a>' +
                '<a href="https://support.apple.com/id-id/HT207092" target="_blank">' +
                '(Safari IOS)</a>'
        })
    }

    goToLink(url: string){
        window.open(url, "_blank");
    }

    setPage(event: any,T) {
        T.offset = event.offset * T.limit;
        T.limit = event.limit;
        T.fetchData();
        T.pageNum = event.offset;
    }

    generateRandomPassword(length: number): string {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
        let password = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset.charAt(randomIndex);
        }

        return password;
    }

    downloadFile(data: any, filename:string, filetype:string) {
        const blob = new Blob([data], { type: filetype });
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    dataURItoBlob(dataURI, type) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: type });
        return blob;
    }
}
