import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';

import { OverlayModule } from '@angular/cdk/overlay';
import {BlockUIModule} from "ng-block-ui";
import {BasicAuthInterceptor} from "./services/basic-auth-interceptor.service";
import {fakeBackendProvider} from "./services/fake-backend-interceptor.service";
import {AdminGuard} from "./shared/guard/admin.guard";
import {DashboardModule} from "./pages/dashboard/dashboard.module";
import {CurrencyPipe} from "@angular/common";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {ManageModules} from "./pages/manage/manage.modules";
import {HttpErrorInterceptor} from "./services/httperrorinterceptor.service";
import {RegistrationComponent} from "./auth/registration/registration.component";
import {SharedCommonModules} from "./components/common/shared-common.modules";
import {LookupModalModule} from "./components/lookup-modal/lookup-modal.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
  ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        OverlayModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
//     // for HttpClient use:
        LoadingBarHttpClientModule,
//     // for Router use:
        LoadingBarRouterModule,
//     // for Core use:
        LoadingBarModule,
        BlockUIModule.forRoot(),
        SweetAlert2Module,
        DashboardModule,
        ManageModules,
        SharedCommonModules,
        LookupModalModule
    ],
  providers: [
      CookieService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    fakeBackendProvider,
    AdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
