import {Component, OnInit, ViewChild} from "@angular/core";
import {HelperService} from "../../../services/helper.service";
import {FixedTable} from "../../../components/common/fixed-table/fixed-table";
import {environment} from "../../../../environments/environment";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {StoreService} from "../../../services/dgpos/store.service";

@Component({
    selector: "today-summary",
    templateUrl: "./today-summary.component.html",
})
export class TodaySummaryComponent implements OnInit{
    today:string;
    @ViewChild('sellingSumm') sellingSumm:FixedTable;
    @ViewChild('paymentMethod') paymentMethod:FixedTable;
    sellingSummHeader= [
        {
            header:"Operator",
            key:"operator"
        },{
            header:"Total",
            key:"total"
        }
    ];
    sellingSummAmount:string="0";
    paymentMethodHeader= [
        {
            header:"Metode Pembayaran",
            key:"payment_method"
        },{
            header:"Total Pembayaran",
            key:"total"
        }
    ];

    constructor(private helper:HelperService,
                private storeService:StoreService,
                private secureApi:SecureJsonApiService) {}

    ngOnInit(): void {
        this.today = this.helper.formatDateWithDay(Date());
        this.storeService.storeSubject.subscribe(async () => {
            this.todaySummaryFromServer().then();
        });
    }

    async todaySummaryFromServer() {
        let param = new HttpParams();
        param = param.set('start_date', this.helper.formatDateOnly(Date()));
        param = param.set('end_date', this.helper.formatDateOnly(Date()));
        let todaySummary = await this.secureApi
            .asyncAwaitRequestParamCommon(
                environment.report.summary,
                param
            );
        if(todaySummary?.data){
            this.sellingSummAmount = todaySummary.data?.info["total_penjualan"].toString();
            this.sellingSumm.pushData(todaySummary.data?.sales ?? []);
            let paymentMethodData = todaySummary.data?.payment ?? [];
            const totalPendapatan = {
                payment_method: "Total Pendapatan",
                total: todaySummary.data?.info["total_pendapatan"]
            }
            paymentMethodData.push(totalPendapatan);
            const totalPengeluaran = {
                payment_method: "Total Pengeluaran",
                total: todaySummary.data?.info["total_pengeluaran"]
            }
            paymentMethodData.push(totalPengeluaran);
            this.paymentMethod.pushData(paymentMethodData);
        }
    }
}
