import {Component, OnInit, ViewChild} from "@angular/core";
import {SecureJsonApiService} from "../../services/secure-rest.service";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";
import {HttpParams} from "@angular/common/http";
import {FixedTable} from "../../components/common/fixed-table/fixed-table";
import {StoreService} from "../../services/dgpos/store.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.page.html",
})
export class DashboardPage implements OnInit{
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('recentOrder') recentOrder:FixedTable;
    @ViewChild('topProduct') topProduct:FixedTable;
    storeInfo:any;
    public todaySales = {
        icon: "cart",
        counter: "0",
        name: "Penjualan Hari Ini",
        font: "secondary",
        pr: "-20",
    }
    public debtTotal = {
        icon: "return-box",
        counter: "0",
        name: "Total Piutang",
        font: "warning",
        pr: "+50",
    };
    public invoiceTotal= {
        icon: "tag",
        counter: "0",
        name: "Invoice Hari Ini",
        font: "primary",
        pr: "+70",
    };
    public invoiceCancel = {
        icon: "rate",
        counter: "0",
        name: "Invoice Cancel",
        font: "success",
        pr: "+70",
    };
    public cashIn = {
        icon: "rate",
        counter: "0",
        name: "Uang Masuk Hari Ini",
        font: "secondary",
        pr: "+70",
    };
    public depositTotal = {
        icon: "rate",
        counter: "0",
        name: "Total Deposit",
        font: "primary",
        pr: "+70",
    };
    recentOrderHLK: any[] = [
        {
            header:"Produk",
            key:"name_product"
        },{
            header:"Qty",
            key:"qty"
        },{
            header:"Harga",
            key:"price"
        },{
            header:"Diskon",
            key:"disc"
        },{
            header:"Sub Total",
            key:"totalprice"
        }
    ];

    topProductHLK: any[] = [
        {
            header:"Produk",
            key:"name_product"
        },{
            header:"Terjual",
            key:"terjual"
        }
    ];

    constructor(private secureApi: SecureJsonApiService,
                private storeService: StoreService) {
    }

    async ngOnInit(): Promise<void> {
        this.blockUI.start("Loading...");
        console.log("dashboard store init")
        this.storeService.storeSubject.subscribe(async store => {
            console.log("dashboard store")
            this.blockUI.start("Loading...");
            if (store) {
                this.setStoreInfo(store);
                await this.recentOrderFromServer().then();
                await this.topProductFromServer().then();
            }
            this.blockUI.stop();
        });
        this.blockUI.stop();
    }

    setStoreInfo(currentStore: any) {
        this.storeInfo = currentStore.data;
        this.todaySales.counter = this.storeInfo["sales_today"]!="" ?this.storeInfo["sales_today"]: 0;
        this.debtTotal.counter = this.storeInfo["total_piutang"]!="" ?this.storeInfo["total_piutang"]: 0;
        this.invoiceTotal.counter = this.storeInfo["total_invoice"]!="" ?this.storeInfo["total_invoice"]: 0 ;
        this.invoiceCancel.counter = this.storeInfo["sales_cancel"]!="" ?this.storeInfo["sales_cancel"]: 0;
        this.cashIn.counter = this.storeInfo["cash_in"]!="" ?this.storeInfo["cash_in"]: 0;
        this.depositTotal.counter = this.storeInfo["total_deposit"]!="" ?this.storeInfo["total_deposit"]: 0;
    }

    async recentOrderFromServer() {
        let recentOrderList = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.recentOrder,
                new FormData(),
                {offset: 0, limit: 10}
            );
        if(recentOrderList?.data?.length > 0){
            recentOrderList.data.forEach((data:any)=>{
                data.qty = data.qty + " " + data['prod_weight'];
            })
            this.recentOrder.pushData(recentOrderList.data);
        }
    }
    async topProductFromServer() {
        let topProductList = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.topProduct,
                new FormData(),
                {offset: 0, limit: 10}
            );
        if(topProductList?.data?.length > 0){
            topProductList.data.forEach((data:any)=>{
                data.terjual = data.terjual + " " + data['prod_weight'];
            })
            this.topProduct.pushData(topProductList.data);
        }
    }
}
