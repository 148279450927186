import { Routes } from "@angular/router";
import {SupportModule} from "../../pages/support/support.module";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../pages/dashboard/dashboard.module")
        .then((m) => m.DashboardModule),
  },{
    path: "manage",
    loadChildren: () => import("../../pages/manage/manage.modules")
        .then((m) => m.ManageModules),
  },{
    path: "transaction",
    loadChildren: () => import("../../pages/transaction/transaction.module")
        .then((m) => m.TransactionModule),
  },{
    path: "support",
    loadChildren: () => import("../../pages/support/support.module")
        .then((m) => m.SupportModule),
  },{
    path: "discount",
    loadChildren: () => import("../../pages/discount/discount.module")
        .then((m) => m.DiscountModule),
  },
];
