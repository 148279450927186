<label class="form-label" for="validation{{label}}">{{label}}</label>
<div class="input-group">
    <div class="col-6 mb-1">
        <div class="dp-hidden position-absolute">
            <div class="input-group">
                <input name="datepicker" class="form-control datepicker-here"
                       ngbDatepicker #datepicker="ngbDatepicker"
                       [autoClose]="'outside'"
                       (dateSelect)="onDateSelection($event)"
                       [displayMonths]="2" [dayTemplate]="t"
                       outsideDays="hidden"
                       [startDate]="fromDate!" tabindex="-1" />
                <ng-template #t let-date let-focused="focused">
                                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                  </span>
                </ng-template>
            </div>
        </div>
        <div class="input-group">
            <input #dpFromDate class="form-control datepicker-here" placeholder="yyyy-mm-dd" name="dpFromDate"
                   [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                   (click)="datepicker.toggle()" />
        </div>
    </div>
    <div class="col-6 mb-1">
        <div class="input-group">
            <input #dpToDate class="form-control datepicker-here" placeholder="yyyy-mm-dd" name="dpToDate"
                   [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                   (click)="datepicker.toggle()" />
        </div>
    </div>
    <div class="invalid-feedback"  *ngIf="requiredValue">
        <span class="badge badge-danger">{{ formControl.invalid ? invalid() : requiredValue}}</span>
    </div>
</div>
<div class="m-1" *ngIf="notes"><span class="badge badge-light-success">{{notes}}</span></div>

