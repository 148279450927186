import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef, EventEmitter,
    forwardRef,
    inject,
    Input,
    OnInit,
    Optional, Output,
    Self
} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {noop, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

/**
 * @description
 * TextField Component
 *
 * @Input Mandatory:
 * * label:string
 * * formcontrolName or formcontrol
 * **/
@Component({
    selector: 'text-field',
    templateUrl: './text-field.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextField), multi: true}
    ]
})
export class TextField implements ControlValueAccessor, OnInit{
    formControl: FormControl = new FormControl<string>('');

    destroyRef: DestroyRef = inject(DestroyRef);

    onChange: (value: string) => void = noop;
    onTouch: () => void = noop;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() requiredValue: string;
    @Input() type: string = "text";
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: any;
    @Input() suffixButton: any=false;
    @Input() readOnly: boolean=false;
    @Input() suffixIcon: any="fa-search";

    @Output() blur = new EventEmitter;
    @Output() suffixClickEvent = new EventEmitter();

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    writeValue(value: string): void {
        this.formControl.setValue(value, { emitEvent: false });
    }

    ngOnInit(): void {
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    onBlurEvent() {
        this.blur.emit();
    }

    suffixClick() {
        this.suffixClickEvent.emit();
    }
}
