// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {secretKey} from "./common-envi";
import {urlApi} from "./url-api";
import {menuList} from "./menu-list";

export const environment = {
  production: true,
  apiUrlBase: "https://app-dev.dgpos.id/",
  version: "60",
  accessKey: "03769f5ee64b4517ada26ff98eb165c1",
  maxPageAll: "30",
  secretKey,
  menuList,
  ...urlApi,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
