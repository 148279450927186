import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {PortalModule} from "@angular/cdk/portal";
import {
    NgbDateAdapter,
    NgbDateNativeAdapter,
    NgbDateParserFormatter,
    NgbInputDatepicker
} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../shared/shared.module";
import {TextField} from "./text-field/text-field";
import {NgxDatatableCardBody} from "./ngx-datatable-card-body/ngx-datatable-card-body";
import {Select2Field} from "./select2-field/select2-field";
import {NgSelectModule} from "@ng-select/ng-select";
import {SelectOption} from "./select-option/select-option";
import {FixedTable} from "./fixed-table/fixed-table";
import {CurrencyField} from "./currency-field/currency-field";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {TextareaField} from "./textarea-field/textarea-field";
import {CheckboxField} from "./checkbox-field/checkbox-field";
import {UploadField} from "./upload-field/upload-field";
import {NgxImageCompressService} from "ngx-image-compress";
import {UploadImageCompress} from "./upload-image-compress/upload-image-compress";
import {AutonumericField} from "./autonumeric-field/autonumeric-field";
import {RadiobuttonField} from "./radiobutton-field/radiobutton-field";
import {DatepickerField} from "./datepicker-field/datepicker-field";
import {CustomAdapter, CustomDateParserFormatter} from "./datepicker-field/datepicker-adapter";
import {ModalSharedComponent} from "./modal/modal-shared.component";
import {DatepickerDurationField} from "./datepicker-duration-field/datepicker-duration-field";
import {TextAutocompleteField} from "./text-autocomplete-field/text-autocomplete-field";


@NgModule({
    declarations:[
        TextField,
        Select2Field,
        SelectOption,
        CurrencyField,
        TextareaField,
        NgxDatatableCardBody,
        FixedTable,
        CheckboxField,
        UploadField,
        UploadImageCompress,
        AutonumericField,
        RadiobuttonField,
        DatepickerField,
        ModalSharedComponent,
        DatepickerDurationField,
        TextAutocompleteField,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        PortalModule,
        NgbInputDatepicker,
        SharedModule,
        NgSelectModule,
        CurrencyMaskModule
    ],
    exports:[
        TextField,
        Select2Field,
        SelectOption,
        CurrencyField,
        TextareaField,
        NgxDatatableCardBody,
        FixedTable,
        CheckboxField,
        UploadField,
        UploadImageCompress,
        AutonumericField,
        RadiobuttonField,
        DatepickerField,
        ModalSharedComponent,
        DatepickerDurationField,
        TextAutocompleteField
    ],
    providers:[
        NgxImageCompressService,
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class SharedCommonModules {}
