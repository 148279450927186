<app-breadcrumb [title]="'Manage Supplier'" [items]="['Manage', 'Supplier']"
                [active_item]="'Main'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body text-end">
                    <div class="col-sm-12">
                        <button class="btn btn-success me-1"
                                (click)="add()" type="button">Create</button>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <form class="form theme-form" [formGroup]="formGroup" (ngSubmit)="search()">
                        <div class="row g-3">
                            <div class="col-md-6" style="margin-top:37px">
                                <input class="form-control dgpos-search-input" type="text" formControlName="supplierName" placeholder="Masukan nama supplier">
                            </div>
                            <div class="col-md-6 text-start" style="margin-top:37px">
                                <button class="btn btn-primary me-1"
                                        (click)="search()" type="button">{{ buttonSearchText }}</button>
                                <button class="btn btn-secondary me-1"
                                        (click)="reset()" type="button">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ngx-datatable-cb #dt
                                  [columns]="columns"
                                  option="true"
                                  (callFetchData) = "fetchData($event)"
                                  (selected) = "selectedEvent($event)"
                                  (edit)="selectedEvent($event)"
                                  (delete)="deletedEvent($event)"
                ></ngx-datatable-cb>
            </div>
        </div>
    </div>
</div>
