import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {DOC_ORIENTATION, NgxImageCompressService} from "ngx-image-compress";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * @description
 * UploadField Component
 *
 * @Input Mandatory:
 * * label:string
 * * formcontrolName or formcontrol
 * **/
@Component({
    selector: 'upload-field',
    templateUrl: './upload-field.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UploadField {
    @ViewChild('content') content:NgbModal;
    @Input() label: string;
    @Input() srcUrl: string;
    @Output() imageChangedEvent = new EventEmitter();
    constructor(private imageCompress: NgxImageCompressService,
                private modalService: NgbModal,) {
    }

    async upload(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        const base = await this.toBase64(file);
        console.log('Size in bytes before compression is now:', this.imageCompress.byteCount(base));
        this.imageCompress
            .compressFile(base, DOC_ORIENTATION.Default, 100, 25) // 50% ratio, 50% quality
            .then(compressedImage => {
                console.log('Size in bytes after compression is now:', this.imageCompress.byteCount(compressedImage));
                let blob = this.dataURItoBlob(compressedImage);
                this.imageChangedEvent.emit(blob);
            });
    }

    dataURItoBlob(dataURI: string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //Old Code
        //write the ArrayBuffer to a blob, and you're done
        //var bb = new BlobBuilder();
        //bb.append(ab);
        //return bb.getBlob(mimeString);

        //New Code
        return new Blob([ab], {type: mimeString});


    }
    async toBase64 (file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
    }

    showPicture() {
        this.modalService.open(this.content, {size: 'lg'});
    }
}
