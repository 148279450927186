export const menuList = [
    { path: "/dashboard/default", icon: "home", title: "Dashboard",  active: true, type: "link", bookmark: true },
    { path: "/pos", icon: "project", title: "POS",  active: false, type: "link", bookmark: false },
    {
        title: "Transaksi",
        icon: "bonus-kit",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "4",
        active: false,
        children: [
            { path: "/transaction/purchase/inquiry", title: "Pembelian", type: "link" },
            { path: "/transaction/sales/inquiry", title: "Penjualan", type: "link" },
            { path: "/transaction/deposit/inquiry", title: "Deposit", type: "link" },
            { path: "/transaction/expense/inquiry", title: "Pengeluaran", type: "link" },
        ],
    },
    {
        title: "Hutang Piutang",
        icon: "ui-kits",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "2",
        active: false,
        children: [
            { path: "/debt/supplier", title: "Supplier", type: "link" },
            { path: "/debt/customer", title: "Customers", type: "link" }
        ],
    },
    {
        title: "Laporan",
        icon: "table",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "8",
        active: false,
        children: [
            { path: "/report/cash-history", title: "Riwayat Pembayaran", type: "link" },
            { path: "/report/product-sales", title: "Penjualan Produk", type: "link" },
            { path: "/report/sales", title: "Penjualan", type: "link" },
            { path: "/report/balance", title: "Pendapatan & Pengeluaran", type: "link" },
            { path: "/report/stock", title: "Stok", type: "link" },
            { path: "/report/deposit", title: "Deposit", type: "link" },
            { path: "/report/profit-loss", title: "Laba Rugi", type: "link" },
            { path: "/report/employee-salary", title: "Gaji Pegawai", type: "link" },
        ],
    },
    {
        title: "Diskon",
        icon: "support-tickets",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "2",
        active: false,
        children: [
            { path: "/discount/product/inquiry", title: "Diskon Produk", type: "link" },
            { path: "/discount/transaction/inquiry", title: "Diskon Transaksi", type: "link" }
        ],
    },
    {
        title: "Manage",
        icon: "knowledgebase",
        type: "sub",
        badgeType: "light-primary",
        badgeValue: "8",
        active: false,
        children: [
            { path: "/manage/customer/inquiry", title: "Pelanggan", type: "link" },
            { path: "/manage/vehicle", title: "Kendaraan", type: "link" },
            { path: "/manage/category/inquiry", title: "Kategori", type: "link" },
            { path: "/manage/product/inquiry", title: "Produk", type: "link" },
            { path: "/manage/supplier/inquiry", title: "Supplier", type: "link" },
            { path: "/manage/table", title: "Meja", type: "link" },
            { path: "/manage/outlet/inquiry", title: "Outlet", type: "link" },
            { path: "/manage/users/inquiry", title: "Users", type: "link" },
        ],
    },
    { path: "/setting", icon: "editors", title: "Setting",  active: false, type: "link", bookmark: false }
];
