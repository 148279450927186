<app-breadcrumb [title]="'Manage Users Update Password'" [items]="['manage', 'users']"
                [active_item]="'Update Password'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
 [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Nama"
                                        formControlName="userName"
                                        maxlength="50"
                                        [required]="true">
                                </text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-6">
                                <text-field
                                        label="Password"
                                        formControlName="password"
                                        type="password"
                                        required="true"
                                        minlength="8"
                                        requiredValue="Required, minimum 8 character"
                                ></text-field>
                            </div>
                            <div class="col-6 mb-3">
                                <text-field
                                        label="Re-Type Password"
                                        formControlName="retypePassword"
                                        type="password"
                                        (blur)="retypePassword()"
                                        required="true"
                                        minlength="8"
                                        requiredValue="Required, minimum 8 character"
                                ></text-field>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()"
                                type="button">Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="helper.backNav()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
