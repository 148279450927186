<app-breadcrumb [title]="'Manage Pelanggan'" [items]="['Manage', 'Pelanggan']"
                [active_item]="'Editor'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="form theme-form needs-validation" novalidate [ngClass]="{ 'was-validated': validate }"
                          [formGroup]="formGroup" (ngSubmit)="save()">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <text-field
                                        label="Nama Pelanggan"
                                        formControlName="customerName"
                                        maxlength="50"
                                        [required]="true">
                                </text-field>
                            </div>
                            <div class="col-md-3">
                                <text-field
                                        label="Email"
                                        formControlName="email"
                                        maxlength="100"
                                        type="email">
                                </text-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <text-field
                                        label="No Telepon"
                                        formControlName="phoneNumber"
                                        maxlength="15"
                                        type="number">
                                </text-field>
                            </div>
                        </div>
                        <div class="row g-3">
                            <textarea-field
                                    label="Alamat"
                                    maxlength="100"
                                    formControlName="address">
                            </textarea-field>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-end">
                    <div class="col-sm-9 offset-sm-3">
                        <button class="btn btn-outline-success me-1"
                                (click)="save()"
                                type="button">Simpan</button>
                        <button class="btn btn-outline-secondary me-1"
                                (click)="helper.backNav()" type="button">Batal</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
