import {AfterViewInit, Component} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HelperService} from "../../../../services/helper.service";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpParams} from "@angular/common/http";
import {Md5} from "ts-md5";

@Component({
    templateUrl: "./manage-user-update-password.page.html",
})
export class ManageUserUpdatePasswordPage implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;

    formGroup: FormGroup = this.fb.group({
        userId:[null],
        sid : [null],
        userName : [null],
        password : [null],
        retypePassword : [null],
    });

    dataExist:any;

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                public helper: HelperService,
                private secureApi: SecureJsonApiService) {
        this.blockUI.start("Loading...");
        this.f.sid.setValue(this.activatedRoute.snapshot.params['id'] ?? null);
    }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchData();
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.sid.value)
            return;

        let param = new HttpParams();
        param = param.set('id', this.f.sid.value);
        const dataUsers = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.users.byId, param) as any;
        if(dataUsers?.data?.length>0){
            this.dataExist = dataUsers.data[0];
            this.setData();
        }
    }

    get f() { return this.formGroup.controls; }

    setData(){
        this.formGroup.patchValue({
            userId : this.dataExist["userid"] ?? null,
            userName : this.dataExist["full_name"] ?? null,
            password : this.dataExist[""] ?? null,
            retypePassword : this.dataExist[""] ?? null,
        });
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let url = this.dataExist ? environment.users.update : environment.users.create ;
        let payload = new FormData();
        const md5 = new Md5();
        const passwordHash = md5.start().appendStr(this.f.password.value).end();
        payload.set("id", this.f.userId.value ?? null)
        payload.set("pass", passwordHash.toString())

        await this.secureApi.saveDataForm(url, payload);
    }

    retypePassword() {
        if(this.f.retypePassword.value != this.f.password.value){
            this.helper.errorMessage("Password tidak sama dengan Re-Type Password")
            this.f.retypePassword.setValue(null);
        }
    }
}
