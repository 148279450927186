<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Pencarian Pelanggan</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="formGroup" (ngSubmit)="search()" novalidate>
                        <div class="row g-3">
                            <div class="col-md-4" style="margin-top:37px">
                                <input class="form-control dgpos-search-input" type="text"
                                       formControlName="filter"
                                       placeholder="Masukan Nama atau kode pelanggan">
                            </div>
                            <div class="col-md-4 mb-3" style="margin-top:37px">
                                <button class="btn btn-primary me-1"
                                        (click)="search()" type="submit">{{ buttonSearchText }}</button>
                                <button class="btn btn-secondary me-1"
                                        (click)="reset()" type="button">Reset</button>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="custom-datatable table-responsive">
                                <ngx-datatable
                                    #myTable
                                    class="material table-striped"
                                    [rows]="rows"
                                    [loadingIndicator]="loadingIndicator"
                                    [scrollbarH]="true"
                                    [columnMode]="'force'"
                                    [headerHeight]="40"
                                    [footerHeight]="45"
                                    [rowHeight]="'auto'"
                                    [externalPaging]="true"
                                    [count]="count"
                                    [offset]="pageNum"
                                    [limit]="limit"
                                    (page)='setPage($event)'
                                    [cssClasses]="{
                                    sortAscending: 'fa fa-caret-up',
                                    sortDescending: 'fa fa-caret-down',
                                    pagerLeftArrow: 'fa  fa-angle-left',
                                    pagerRightArrow: 'fa fa-angle-right',
                                    pagerPrevious: 'fa fa-angle-double-left',
                                    pagerNext: 'fa fa-angle-double-right'
                                }"
                                    [reorderable]="reorderable">
                                    <ngx-datatable-column>
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <b style="font-size:15px">{{'Kode Pelanggan'}}</b>
                                        </ng-template>
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                                                 (click)="selectedEvent(row)">
                                                <p style="height: 22px;" [class]="'text-left'">
                                                    {{row.cust_code}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column>
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <b style="font-size:15px">{{'Nama Pelanggan'}}</b>
                                        </ng-template>
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                                                 (click)="selectedEvent(row)">
                                                <p style="height: 22px;" [class]="'text-left'">
                                                    {{row.name_customer}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column>
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <b style="font-size:15px">{{'Email'}}</b>
                                        </ng-template>
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                                                 (click)="selectedEvent(row)">
                                                <p style="height: 22px;" [class]="'text-left'">
                                                    {{row.email}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column>
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <b style="font-size:15px">{{'No. Telepon'}}</b>
                                        </ng-template>
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                                                 (click)="selectedEvent(row)">
                                                <p style="height: 22px;" [class]="'text-left'">
                                                    {{row.telephone}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column>
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <b style="font-size:15px">{{'Saldo'}}</b>
                                        </ng-template>
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                                                 (click)="selectedEvent(row)">
                                                <p style="height: 22px;" [class]="'text-left'">
                                                    {{row.saldo_cust | number}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-footer>
                                        <ng-template
                                                ngx-datatable-footer-template
                                                let-rowCount="rowCount"
                                                let-pageSize="pageSize"
                                                let-selectedCount="selectedCount"
                                                let-curPage="curPage"
                                                let-offset="offset"
                                                let-isVisible="isVisible">
                                            <div class="page-count">
                                                <div>
                                                    {{selectedCount}} selected / {{recordsTotal}} total
                                                </div>
                                            </div>
                                            <datatable-pager
                                                    [pagerLeftArrowIcon]="'fa  fa-angle-left'"
                                                    [pagerRightArrowIcon]="'fa fa-angle-right'"
                                                    [pagerPreviousIcon]="'fa fa-angle-double-left'"
                                                    [pagerNextIcon]="'fa fa-angle-double-right'"
                                                    [page]="curPage"
                                                    [size]="pageSize"
                                                    [count]="rowCount"
                                                    [hidden]="!((rowCount / pageSize) > 1)"
                                                    (change)="myTable.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>
                                </ngx-datatable>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
