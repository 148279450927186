import {BehaviorSubject, Observable} from "rxjs";
import {UserAuth} from "../models/user-auth.model";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {Md5} from "ts-md5";
import {v4 as uuidv4} from 'uuid';
import {formatDate} from "@angular/common";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {StoreService} from "./dgpos/store.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<UserAuth>;
    public user: Observable<UserAuth>;

    constructor(
        private router: Router,
        private http: HttpClient,
        @Inject(LOCALE_ID) public locale: string
    ) {
        this.userSubject = new BehaviorSubject<UserAuth>(JSON.parse(localStorage.getItem('userAuth')));
        this.user = this.userSubject.asObservable();
    }

    public setUserAuth(userAuth: UserAuth){
        this.userSubject.next(userAuth);
        this.refreshUserAuthStorage();
    }

    public get userValue(): UserAuth {
        return this.userSubject.value;
    }

    public refreshUserAuthStorage(){
        this.user = this.userSubject.asObservable();
        localStorage.setItem('userAuth', JSON.stringify(this.userSubject.value));
    }

    public clearUserAuth(){
        this.user = null;
        this.userSubject.next(null);
        localStorage.clear();
    }

    login(outletId:string, email: string, password: string) {
        // localStorage.removeItem('userAuth');
        // this.userSubject.next(null);
        this.clearUserAuth();
        const md5 = new Md5();
        const passwordHash = md5.start().appendStr(password).end();
        let formData = new FormData();
        formData.set("accesskey",environment.accessKey);
        formData.set("user",email);
        formData.set("password", passwordHash.toString());
        formData.set("latitude","-6.2954166");
        formData.set("longitude","106.6732071");
        formData.set("appinfo","web");
        formData.set("device_id", uuidv4());

        formData.set("app_time", formatDate(Date(),'yyyy-MM-dd HH:mm:ss', this.locale));
        formData.set("outlet_id", outletId);

        return this.http.post<any>(environment.apiUrlBase+environment.auth.login,
            formData)
            .pipe(map( userAuth => {
                this.setUserAuth(userAuth.data[0]);
                return userAuth.data[0];
            }));
    }

    registration(payload:FormData){
        payload.set("accesskey",environment.accessKey);
        payload.set("latitude","-6.2954166");
        payload.set("longitude","106.6732071");
        return this.http.post<any>(environment.apiUrlBase+environment.auth.register,
            payload)
            .pipe(map(result => {
                // this.setUserAuth(userAuth.data[0]);
                return result;
            }));
    }


    logout() {
        this.clearUserAuth();
        this.router.navigate(['/auth/login']).then();
    }
}
