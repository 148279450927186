<label class="form-label" for="validation{{label}}">{{label}}</label>
<div class="input-group mb-3">
    <div class="btn-group">
        <input class="btn btn-outline-primary"
               id="validation{{label}}"
               placeholder="Choose Your Image" type="file"
               (change)="upload($event)" />
        <button class="btn btn-outline-primary bootstrap-touchspin-up"
                placement="top" ngbTooltip="Tampilkan Gambar"
                type="button" (click)="showPicture()">
            <i class="fa fa-file-photo-o"></i>
        </button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Images</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <img  class="images" [src]="srcUrl" alt="" width="100%" height="100%" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-warning" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>


