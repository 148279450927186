import {AfterViewInit, Component} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HelperService} from "../../../../services/helper.service";
import {SecureJsonApiService} from "../../../../services/secure-rest.service";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpParams} from "@angular/common/http";

@Component({
    templateUrl: "./manage-supplier-editor.page.html",
})
export class ManageSupplierEditorPage implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;

    formGroup: FormGroup = this.fb.group({
        supplierId : [null],
        supplierName : [null],
        telephone : [null],
        email : [null],
        address : [null]
    });

    dataExist:any;

    constructor(private fb: FormBuilder,
                private activatedRoute:ActivatedRoute,
                public helper: HelperService,
                private router : Router,
                private secureApi: SecureJsonApiService) {
        this.blockUI.start("Loading...");
        this.f.supplierId.setValue(this.activatedRoute.snapshot.params['id'] ?? null)
    }

    get f() { return this.formGroup.controls; }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchData();
        this.blockUI.stop();
    }

    private async fetchData() {
        if (!this.f.supplierId.value)
            return;

        let param = new HttpParams();
        param = param.set('id', this.f.supplierId.value);
        const dataSupplier = await this.secureApi
            .asyncAwaitRequestParamCommon(environment.supplier.list, param) as any;
        if(dataSupplier?.data?.length>0){
            this.dataExist = dataSupplier.data[0];
            this.setData();
        }
    }

    setData(){
        this.formGroup.patchValue({
            supplierId : this.dataExist["id_supplier"] ?? null,
            supplierName : this.dataExist["name_supplier"] ?? null,
            telephone : this.dataExist["email"] ?? null,
            email : this.dataExist["telephone"] ?? null,
            address : this.dataExist["address"] ?? null,
        });
    }

    public validate = false;
    async save() {
        if(this.formGroup.invalid){
            this.validate = true;
            return;
        }
        let url = this.dataExist ? environment.supplier.update : environment.supplier.create ;
        let payload = new FormData();
        payload.set("id", this.f.supplierId.value ?? null)
        payload.set("name_supplier", this.f.supplierName.value ?? null)
        payload.set("telephone", this.f.telephone.value ?? null)
        payload.set("email", this.f.email.value ?? null)
        payload.set("address", this.f.address.value ?? null)
        await this.secureApi.saveDataForm(url, payload);
    }
}
