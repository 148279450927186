<div class="{{notModeCard? '':'card'}}">
    <div class="{{notModeCard? 'mb-3':'card-header card-no-border'}}">
        <div class="header-top" *ngIf="!noHeader">
            <h5 class="m-0">{{title}}
                <span *ngIf="subTitle" class="badge badge-danger">{{subTitle}}</span>
                <span *ngIf="subSubTitle" class="badge badge-primary">
                     {{ checkNumber(subSubTitle) ?
                    subSubTitle :
                    subSubTitle | number }}</span>
            </h5>
            <div class="card-header-right-icon" *ngIf="option">
                <div class="dropdown icon-dropdown">
                    <button class="btn dropdown-toggle" type="button" (click)="toggle()"><i class="icon-more-alt"></i></button>
                    <div class="dropdown-menu dropdown-menu-end" [class.show]="show"><a class="dropdown-item" href="#">Today</a><a class="dropdown-item" href="#">Tomorrow</a><a class="dropdown-item" href="#">Yesterday</a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="{{notModeCard? '':'card-body pt-0 campaign-table'}}">
        <div class="recent-table table-responsive">
            <table class="table">
                <thead *ngIf="!noHeader">
                <tr>
                    <th class="f-light" *ngFor="let header of headerListAndKey">{{header.header}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let items of detailList">
                    <td *ngFor="let key of headerListAndKey">
                        <span *ngIf="key?.type == 'status'"
                              class="badge {{items[key.key] == 'Y'? 'badge-light-success': 'badge-light-light'}}">
                            {{items[key.key] == 'Y'? 'Active': 'Expired'}}</span>
                        <span *ngIf="!key?.type">
                            {{ checkNumber(items[key.key]) ?
                            items[key.key] :
                            items[key.key] | number }}
                        </span>
                        </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
