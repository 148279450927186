<div class="{{inRow ? '' : 'card-body custom-datatable noscroll' }}">
    <div class="custom-datatable table-responsive">
        <ngx-datatable
            #myTable
            class="material table-striped"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [scrollbarH]="true"
            [columnMode]="'force'"
            [headerHeight]="40"
            [footerHeight]="45"
            [rowHeight]="'auto'"
            [externalPaging]="true"
            [count]="count"
            [offset]="pageNum"
            [limit]="limit"
            [externalSorting]="true"
            (sort)="onSort($event)"
            (page)='setPage($event);'
            [cssClasses]="{
                                        sortAscending: 'fa fa-caret-up',
                                        sortDescending: 'fa fa-caret-down',
                                        pagerLeftArrow: 'fa  fa-angle-left',
                                        pagerRightArrow: 'fa fa-angle-right',
                                        pagerPrevious: 'fa fa-angle-double-left',
                                        pagerNext: 'fa fa-angle-double-right'
                                    }"
            [reorderable]="reorderable">

            <ngx-datatable-column *ngIf="option" [width]="100">
                <ng-template ngx-datatable-header-template>
                    <b style="font-size:13px">Action</b>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div style="width: 100px; height: 100%; padding: 5px;">
                        <div class="action text-center">
                            <li class="edit" (click)="editEvent(row)">
                                <a [ngbTooltip]="tooltipUpdateTxt"><i class="icon-pencil-alt"></i></a>
                            </li>
                            <li class="delete" *ngIf="!hideDeleteButton" (click)="deleteEvent(row)">
                                <a [ngbTooltip]="'Delete Data'"><i class="icon-trash"></i></a>
                            </li>
                            <li *ngIf="actionOption1" class="{{actionOption1.class}}" (click)="actionOption1Click(row)">
                                <a [ngbTooltip]="actionOption1.tooltip"><i class="{{actionOption1.icon}}"></i></a>
                            </li>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" *ngFor="let dataColumn of columns">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <b style="font-size:13px">{{dataColumn.label}}</b>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div style="width: 100%; height: 100%; padding: 5px 11px 5px 11px;"
                         (click)="selectedEvent(row)">
                        <p style="height: 22px;" [class]="'text-left'" *ngIf="dataColumn.type == null">
                            {{row[dataColumn['dataName']]}}
                        </p>
                        <p style="height: 22px;" [class]="'text-end'" *ngIf="dataColumn.type == 'number'">
                            {{row[dataColumn['dataName']] | number}}
                        </p>
                        <p style="height: 22px;" [class]="'text-end'" *ngIf="dataColumn.type == 'inputNumber'">
                            <input class="form-control digits"
                            (blur)="onBlurInputNumber($event, row)"
                                   (change)="onChangeInputNumber($event, row)"
                            type='number'
                                   [value]="row[dataColumn['dataName']] | number"
                            autocomplete="off"/>
                        </p>
                        <p style="height: 22px;" [class]="'text-center'" *ngIf="dataColumn.type == 'status'">
                            <span class="badge {{ row[dataColumn['dataName']] == dataColumn['trueValue'] ?
                                'badge-light-success' : 'badge-light-light' }}">
                                {{row[dataColumn['dataName']]}}</span>
                        </p>
                        <p style="height: 22px;" [class]="'text-end'" *ngIf="dataColumn.type == 'notes'">
                            <input class="form-control"
                                   (blur)="onBlurNote($event, row)"
                                   type='text'
                                   [value]="row[dataColumn['dataName']] ?? ''"
                                   autocomplete="off"/>
                        </p>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                        ngx-datatable-footer-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        let-isVisible="isVisible">
                    <div class="page-count">
                        <div>
                            Showing {{curPage * pageSize > rowCount ? rowCount : curPage * pageSize }} of {{recordsTotal}} row(s)
                        </div>
                    </div>
                    <datatable-pager
                            [pagerLeftArrowIcon]="'fa fa-angle-left'"
                            [pagerRightArrowIcon]="'fa fa-angle-right'"
                            [pagerPreviousIcon]="'fa fa-angle-double-left'"
                            [pagerNextIcon]="'fa fa-angle-double-right'"
                            [page]="curPage"
                            [size]="pageSize"
                            [count]="rowCount"
                            [hidden]="!((rowCount / pageSize) > 1)"
                            (change)="myTable.onFooterPage($event)">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
