import {AfterViewInit, Component, ComponentRef, Input, ViewChild} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ComponentPortal, Portal} from "@angular/cdk/portal";

@Component({
    selector:"modal-shared",
    templateUrl: "./modal-shared.component.html",
})
export class ModalSharedComponent implements AfterViewInit {
    @Input() viewContainerRef:ComponentPortal<any>;
    @ViewChild('content') content:NgbModal
    @Input() selectedPortal: Portal<any>;
    @Input() id:string;
    @Input() title:string;
    constructor(
        private modalService: NgbModal
    ) {
    }

    public onComponentRendering(ref): void {
        console.log("id : "+this.id);
        ref = ref as ComponentRef<any>;
        ref.instance['dataId'] = this.id;
    }
    open() {
        if(!this.id) return;

        this.modalService.open(this.content, { size: 'lg' });
    }
    addChild(){
        this.selectedPortal = this.viewContainerRef;
    }

    ngAfterViewInit(): void {
        this.selectedPortal = this.viewContainerRef;
    }

}
