<app-breadcrumb [title]="'Dashboard'"  [active_item]="'Dashboard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row widget-grid">
        <div class="col-xxl-4 col-xl-4 col-md-3 col-sm-6 box-col-6">
            <app-welcome></app-welcome>
        </div>
        <div class="col-xxl-auto col-xl-auto col-md-3 col-sm-6 box-col-6">
            <div class="row">
                <div class="col-xl-12">
                    <app-product-status-chart-box [data]="todaySales"></app-product-status-chart-box>
                    <div class="col-xl-12">
                        <app-product-status-chart-box [data]="debtTotal"></app-product-status-chart-box>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxl-auto col-xl-auto col-md-3 col-sm-6 box-col-6">
            <div class="row">
                <div class="col-xl-12">
                    <app-product-status-chart-box [data]="invoiceTotal"></app-product-status-chart-box>
                    <div class="col-xl-12">
                        <app-product-status-chart-box [data]="invoiceCancel"></app-product-status-chart-box>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxl-auto col-xl-auto col-md-3 col-sm-6 box-col-6">
            <div class="row">
                <div class="col-xl-12">
                    <app-product-status-chart-box [data]="cashIn"></app-product-status-chart-box>
                    <div class="col-xl-12">
                        <app-product-status-chart-box [data]="depositTotal"></app-product-status-chart-box>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxl-12 col-lg-12 col-xl-12 col-sm-12 box-col-12">
            <sales-chart></sales-chart>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-7">
            <fixed-table #recentOrder
                         [headerListAndKey]="recentOrderHLK"
                         title="Recent Order"
            ></fixed-table>
        </div>
        <div class="col-xl-5">
            <fixed-table #topProduct
                         [headerListAndKey]="topProductHLK"
                         title="Top Product">

            </fixed-table>
        </div>
    </div>
</div>
