import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {AuthenticationService} from "../../../../../services/authentication.service";
import {SecureJsonApiService} from "../../../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {HelperService} from "../../../../../services/helper.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public authService:AuthenticationService,
              private helper:HelperService,
              private secureApi:SecureJsonApiService) {
  }

  ngOnInit() { }

  async logoutFunc() {
    this.blockUI.start("Loading...");

    let params = new HttpParams();
    params = params.set("key",this.authService.userValue.key);
    params = params.set("token_user",this.authService.userValue.dgtl_token);
    params = params.set("accesskey",environment.accessKey);
    params = params.set("app_time", this.helper.formatDate(Date()));
    const result = await this.secureApi.asyncAwaitRequestParam(
        environment.auth.logout,
        params
    );
    if(result){
      this.authService.logout();
    }
    this.blockUI.stop();
  }
}
