import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject, Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {noop, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

import AutoNumeric from 'autonumeric';

/**
 * @description
 * autonumeric-field Component
 *
 * @Input Point Parameter:
 * * label:string
 * * formcontrolName or formcontrol
 * * defaultType : percent, currency
 * * currencyMaskOptions see https://docs.autonumeric.org/Documentation/configuration%20options/
 * **/
@Component({
    selector: 'autonumeric-field',
    templateUrl: './autonumeric-field.html',
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutonumericField), multi: true}
    ]
})
export class AutonumericField implements ControlValueAccessor, OnInit, AfterViewInit{
    formControl: FormControl = new FormControl<number>(null);
    @ViewChild('selector') inputRef : ElementRef;
    destroyRef: DestroyRef = inject(DestroyRef);

    onChange: (value: string) => void = noop;
    onTouch: () => void = noop;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() requiredValue: string = "required !!"
    @Input() currencyMaskOptions:any = {
        suffixText: " %",
        decimalPlaces: 6,
    };
    @Input() defaultType:string;//percent, currency
    @Input() maxValue:number;
    @Input() minValue:number;

    @Output() onChangeEvent = new EventEmitter();
    elementRef:any;

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    writeValue(value: string): void {
        if(this.formControl.value !== value){
            console.log("writeValue");
            let formatValue = AutoNumeric.format(value, this.currencyMaskOptions);
            this.elementRef.set(value);
            this.formControl.setValue(formatValue, { emitEvent: false });
        }
    }

    setDefaultType(){
        switch (this.defaultType) {
            case "percent":
                this.currencyMaskOptions = {
                    decimalPlaces : 0,
                    maximumValue: this.maxValue ?? 100,
                    minimumValue: this.minValue ?? -100,
                    suffixText: " %",
                }
                break;
            case "currency":
                this.currencyMaskOptions = {
                    decimalPlaces : 2
                }
                break;
            default:
                this.currencyMaskOptions = {
                    decimalPlaces : 6
                }
                break;
        }
    }
    ngAfterViewInit(): void {
        console.log("draf")
        this.currencyMaskOptions.emptyInputBehavior = "null";
        this.currencyMaskOptions.modifyValueOnWheel = false;
        this.elementRef = new AutoNumeric(this.inputRef.nativeElement, this.currencyMaskOptions)
    }

    ngOnInit(): void {
        this.setDefaultType();
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }
}
