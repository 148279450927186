import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    forwardRef,
    inject,
    Input,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, noop, Observable, tap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'select2-field',
    templateUrl: './select2-field.html',
    styleUrls: ['./select2-field.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => Select2Field), multi: true}
    ]
})
export class Select2Field implements ControlValueAccessor, OnInit{
    formControl: FormControl = new FormControl<Select2>(null);

    destroyRef: DestroyRef = inject(DestroyRef);
    items:Observable<Select2[]>;
    dataSelect2 = new BehaviorSubject<any[]>([]);
    loading:boolean=true;

    onChange: (value: string) => void = noop;
    onTouch: () => void = noop;

    @Input() multiple: any=false;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() requiredValue: string;
    @Input() url: string;
    @Input() field:string[]=[ "id_category","name_category"];
    @Input() parameter:string = null;
    @Input() manualValue:Select2[];
    @Input() noLabel:boolean=false;

    constructor(private secureApi: SecureJsonApiService,
    ) {
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    writeValue(value: string): void {
        this.formControl.setValue(value, { emitEvent: false });
    }

    async ngOnInit(): Promise<void> {
        this.loading=true;
        await this.renderSelectData().then(() => {
            this.formControl.markAsTouched({onlySelf: true});
            this.formControl.markAsDirty({onlySelf: true});
            this.formControl.updateValueAndValidity({onlySelf: true});
        });
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
        this.loading=false;
    }

    async renderSelectData(){
        if(this.parameter) {
            await this.getSelect2Parameter().then();
        }else if(this.manualValue?.length>0){
            this.setManualValue();
        }else{
            await this.getSelect2().then();
        }
    }

    setManualValue(){
        this.dataSelect2.next(this.manualValue);
        this.items = this.dataSelect2.asObservable();
    }

    async getSelect2Parameter(){
        const result = await this.secureApi
            .asyncAwaitRequestParamCommon(
                environment.parameter.base
                +this.parameter
                +".php"
                , new HttpParams()) as any;
        if(result?.data?.length > 0){
            let select2List = [];
            result.data.forEach((data: { [x: string]: any; })=>{
                const select2Data = new Select2(
                    data["p_val"],
                    data["p_desc"],
                    data
                );
                select2List.push(select2Data);
            });
            this.dataSelect2.next(select2List);
            this.items = this.dataSelect2.asObservable();
        }
    }

    async getSelect2(){
        const result = await this.secureApi
            .asyncAwaitRequestParamCommon(
                this.url, new HttpParams()) as any;
        if(result){
            this.dataSelect2.next(this.processingDataToSelect2(result.data));
            this.items = this.dataSelect2.asObservable();
        }
    }

    processingDataToSelect2(dataList:any[]){
        let select2List = [];
        dataList.forEach(data=>{
            const select2Data = new Select2(
                data[this.field[0]],
                data[this.field[1]],
                data
            );
            select2List.push(select2Data);
        });
        return select2List;
    }

}
export class Select2 {
    constructor(id: string, text: string, data: any) {
        this.id = id;
        this.text=Select2.ucwords(text);
        this.data=data;
    }
    id:string;
    text:string;
    data:any;

    private static ucwords(input: string): string {
        const words = input.split(' ');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }
}
