import {Component, Inject, LOCALE_ID, ViewChild} from "@angular/core";
import {overallBalance} from "../../../shared/data/chart.data";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../../services/authentication.service";
import {environment} from "../../../../environments/environment";
import {ChartComponent} from "ng-apexcharts";
import {formatCurrency} from "@angular/common";
import {StoreService} from "../../../services/dgpos/store.service";

export interface Balance {
    icon: string;
    title: string;
    price: string;
    growth: string;
    colorClass: string;
    show?: boolean;
}
@Component({
    selector: "sales-chart",
    templateUrl: "./sales-chart.component.html"
})
export class SalesChartComponent {
    @ViewChild("chart") chart: ChartComponent;
    public show: boolean = false
    overallBalance = overallBalance;
    chartType: any="Weekly";

    constructor(private secureApi: SecureJsonApiService,
                private authentication: AuthenticationService,
                private storeService:StoreService,
                @Inject(LOCALE_ID) public locale: string) {}

    ngOnInit(): void {
        this.storeService.storeSubject.subscribe(async store => {
            this.getDataSalesChart("weekly").then();
        });
    }

    async getDataSalesChart(type: string) {
        this.chartType = type=="weekly"?"Weekly":"Monthly";
        let params = new HttpParams();
        params = params.set("key",this.authentication.userValue.key);
        params = params.set("token_user",this.authentication.userValue.dgtl_token);
        let result = await this.secureApi.asyncAwaitRequestParam(
            environment.charts[type],
            params
        );
        if(result){
            const data = result.data;
            let newSeries = [];
           this.overallBalance.series.forEach(serie=>{
               if(serie.name == "Pengeluaran"){
                   serie.data = data?.expense[0]?.value ?? [];
               }else if(serie.name == "Penjualan"){
                   serie.data = data?.sales[0]?.value ?? [];
               }
               newSeries.push(serie);
           });
           this.overallBalance.xaxis.categories = data.sales[0].label;
           this.chart.updateSeries(newSeries);
           await this.chart.updateOptions({
               xaxis: {
                   categories: data.sales[0].label
               }
           })
           this.balance.forEach(dataBalance=>{
               if(dataBalance.icon == "income"){
                   dataBalance.price =  formatCurrency(data['total_sales'],this.locale, "IDR ");
               }else if(dataBalance.icon == "expense"){
                   dataBalance.price = formatCurrency(data['total_expense'],this.locale, "IDR ");
               }
           })
        }
    }


    toggle(item: Balance) {
        item.show = !item.show;
    }

    public balance: Balance[] = [
        {
            icon: "income",
            title: "Penjualan",
            price: "0",
            growth: "",
            colorClass: "success",
        },
        {
            icon: "expense",
            title: "Pengeluaran",
            price: "0",
            growth: "",
            colorClass: "danger",
        }
    ];

    dropdown() {
        this.show = !this.show
    }
}
