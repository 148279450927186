export const urlModul = {
    auth:"auth",
    store:"stores",
    charts: "charts",
    datatables: "datatables/index.php?",
    category: "category",
    parameter: "parameter",
    product: "product",
    supplier: "supplier",
    customer: "customer",
    users: "users",
    report: "report",
    profile: "profile",
    discountTransaction: "discount",
    discountProduct: "discount",
    deposit : "deposit",//TODO
    expense : "expense",//TODO
    purchase : "purchase",//TODO
    sales : "sales",//TODO
}
export const urlApi = {
    auth : {
        "login": urlModul.auth+"/login.php",
        "register": urlModul.auth+"/register.php",
        "logout": urlModul.auth+"/logout.php"
    },
    expense:{
        "create": urlModul.expense+"/insert.php",
        "update": urlModul.expense+"/update.php",
        "delete": urlModul.expense+"/delete.php",
        "byId": urlModul.expense+"/getbyid.php",
    },
    deposit:{
        "create": urlModul.deposit+"/insert.php",
        "update": urlModul.deposit+"/update.php",
        "delete": urlModul.deposit+"/delete.php",
        "byId": urlModul.deposit+"/getbyid.php",
    },
    purchase:{
        "create": urlModul.purchase+"/insert.php",
        "update": urlModul.purchase+"/update.php",
        "delete": urlModul.purchase+"/delete.php",
        "byId": urlModul.purchase+"/getbyid.php",
    },
    sales:{
        "create": urlModul.sales+"/insert.php",
        "update": urlModul.sales+"/update.php",
        "delete": urlModul.sales+"/delete.php",
        "byId": urlModul.sales+"/getbyid.php",
    },
    discountProduct:{
        "create": urlModul.discountProduct+"/add-discount-product.php",
        "update" : urlModul.discountProduct+"/update.php",
        "delete" : urlModul.discountProduct+"/delete.php",
        "byId" : urlModul.discountProduct+"/detail-product.php",
        "history" : urlModul.discountProduct+"/product-disc-list.php"
    },
    discountTransaction:{
        "create": urlModul.discountTransaction+"/insert.php",
        "delete" : urlModul.discountTransaction+"/delete.php",
        "byId" : urlModul.discountTransaction+"/detail-transaction.php"
    },
    profile:{
        "profile": urlModul.profile+"/profile.php",
        "updatePassword" : urlModul.profile+"/update-password.php",
        "updateProfile" : urlModul.profile+"/update.php"
    },
    report:{
        "summary": urlModul.report+"/summary.php",
    },
    users:{
        "create": urlModul.users+"/add.php",
        "update": urlModul.users+"/update.php",
        "delete": urlModul.users+"/delete.php",
        "byId": urlModul.users+"/detail.php",
    },
    customer: {
        "create": urlModul.customer+"/insert.php",
        "update": urlModul.customer+"/update.php",
        "delete": urlModul.customer+"/delete.php",
        "byId": urlModul.customer+"/getbyid.php",
    },
    store : {
        "dashboard": urlModul.store+"/dashboard.php",
        "storeList": urlModul.store+"/list.php",
        "switchStore": urlModul.store+"/switch.php",
        "create": urlModul.store+"/addstore.php",
        "update": urlModul.store+"/update.php",
        "delete": urlModul.store+"/delete.php",
        "byId": urlModul.store+"/store-detail.php"
    },
    charts: {
        "monthly" : urlModul.charts+"/monthly-sales.php",
        "weekly" : urlModul.charts+"/sales.php"
    },
    datatables: {
        "product" : urlModul.datatables+"/product",
        "categories" : urlModul.datatables+"/categories",
        "supplier" : urlModul.datatables+"/suppliers",
        "recentOrder" : urlModul.datatables+"/recent-orders",
        "topProduct" : urlModul.datatables+"/top-products",
        "storeList" : urlModul.datatables+"/stores",
        "customer" : urlModul.datatables+"/customers",
        "users" : urlModul.datatables+"/users",
        purchase :  urlModul.datatables+"/purchase",//TODO
        sales :  urlModul.datatables+"/sales",
        deposit :  urlModul.datatables+"/deposit",//TODO
        expense :  urlModul.datatables+"/expense",//TODO
        discountTransaction :  urlModul.datatables+"/discount-transaction",
        discountProduct :  urlModul.datatables+"/discount-products",
    },
    category: {
        "select2" : urlModul.category+"/list.php",
        "create" : urlModul.category+"/insert.php",
        "update" : urlModul.category+"/update.php",
        "delete" : urlModul.category+"/delete.php",
        "list" : urlModul.category+"/list.php",
    },
    product: {
        "select2" : urlModul.product+"/list.php",
        "create" : urlModul.product+"/insert.php",
        "update" : urlModul.product+"/update.php",
        "delete" : urlModul.product+"/delete.php",
        "getProductById" : urlModul.product+"/product.php",
        "list" : urlModul.product+"/list.php",
    },
    supplier: {
        "create" : urlModul.supplier+"/insert.php",
        "update" : urlModul.supplier+"/update.php",
        "delete" : urlModul.supplier+"/delete.php",
        "list" : urlModul.supplier+"/list.php",
    },
    parameter: {
        "base" : urlModul.parameter+"/",
        "province" : urlModul.parameter+"/provinsi.php",
    }
}
