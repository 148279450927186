import {Component, Input} from '@angular/core';

@Component({
    selector: 'fixed-table',
    templateUrl: './fixed-table.html',
})
export class FixedTable {

    public show: boolean = false

    toggle() {
        this.show = !this.show
    }

    @Input() option:false;
    @Input() title:string;
    @Input() subTitle:string;
    @Input() subSubTitle:string;
    @Input() notModeCard:boolean=false;
    @Input() noHeader:boolean=false;

    /**
     * @param headerList List String ex:[{header:"Header 1", key:"value1"}]
     * * key : value1 representing parameter detail of detailList see param detailList
     */
    @Input() headerListAndKey = [];
    /**
     * @param detailList List Object any
     */
    @Input() detailList:any[] = [];

    public pushData(detailList){
        this.detailList = [];
        this.detailList.push(...detailList);
    }

    checkNumber(data){
        return isNaN(data);
    }

    public campaigns = [
        {
            colorClass: "facebook",
            icon: "facebook",
            name: "Jane Cooper",
            country: "UK",
            growth: "45.6",
            growthArrow: "trending-up",
            amount: "9,786",
            badge: "Active"
        },
        {
            colorClass: "instagram",
            icon: "instagram",
            name: "Floyd Miles",
            country: "DE",
            growth: "12.3",
            growthArrow: "trending-down",
            amount: "19,7098",
            badge: "Active"
        },
        {
            colorClass: "pinterest",
            icon: "pinterest",
            name: "Guy Hawkins",
            country: "ES",
            growth: "65.6",
            growthArrow: "trending-up",
            amount: "90,986",
            badge: "Active"
        },
        {
            colorClass: "twitter",
            icon: "twitter",
            name: "Travis Wright",
            country: "ES",
            growth: "35.6",
            growthArrow: "trending-down",
            amount: "23,654",
            badge: "Inactive"
        },
        {
            colorClass: "you-tube",
            icon: "youtube-play",
            name: "Mark Green",
            country: "UK",
            growth: "45.6",
            growthArrow: "trending-up",
            amount: "12,796",
            badge: "Inactive"
        },
    ]
    protected readonly NaN = NaN;
}
