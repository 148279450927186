import {AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {SecureJsonApiService} from "../../../services/secure-rest.service";
import {environment} from "../../../../environments/environment";
import {HelperService} from "../../../services/helper.service";

@Component({
    selector:"product-lookup",
    templateUrl: "./product-lookup.html",
    styleUrls: ['../../common/ngx-datatable-card-body/ngx-datatable-card-body.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductLookup implements AfterViewInit{
    @ViewChild('content') content: NgbModal
    buttonSearchText = 'Search';
    public selected = [];

    rows: any[];
    offset: number = 0;
    loadingIndicator: boolean = true;
    count: number = 0;
    pageNum: number = 0;
    limit: number = 10;
    reorderable: boolean = true;
    recordsTotal: number = 0;
    pageSize: number = 10;
    formGroup: FormGroup = this.fb.group({
        productName: [null],
        category: [null]
    });
    @Output() selectedEventEmit = new EventEmitter();

    urlCategorySelect2: any=environment.category.select2
    fieldCategorySelect2: any= ["id_category","name_category"];

    constructor(private fb: FormBuilder,
                private router: Router,
                private helper:HelperService,
                private modalService: NgbModal,
                private secureApi: SecureJsonApiService) {
    }

    ngAfterViewInit(): void {
        this.fetchData({offset: 0, limit: 10}).then();
    }
    get f() { return this.formGroup.controls; }
    search() {
        this.buttonSearchText = 'Searching..';
        this.fetchData({offset: 0, limit: 10}).then();
    }

    reset() {
        this.formGroup.reset();
    }

    open() {
        this.modalService.open(this.content, { size: 'xl' });
    }

    async fetchData(event: { offset: any; limit: any; }) {

        let payload = new FormData();
        payload.set("search[name]",this.f.productName.value ?? "");
        payload.set("search[category]",this.f.category.value?.text ?? "");
        const result = await this.secureApi
            .asyncAwaitRequestDT(
                environment.datatables.product,
                payload,
                event
            );
        if (result != null) {
            result['recordsFiltered'] = this.checkMaxPage(result['recordsFiltered'], this.pageSize);
            this.count = result['recordsFiltered'];
            this.recordsTotal = result.recordsTotal;
            this.rows = [];
            this.rows.push(...result.data);
            this.loadingIndicator = false;
        }
        this.buttonSearchText = "Search"
    }

    private checkMaxPage(recordsTotal: any, pageSize: number) {
        const recordsTotalInt : number = parseInt(recordsTotal);
        const maxTotalPage : number = parseInt(localStorage.getItem('maxPageAll') );
        const maxData = maxTotalPage * pageSize;
        if (recordsTotalInt > pageSize) {
            if (recordsTotalInt > maxData) {
                return maxData;
            }
        }

        return recordsTotalInt;
    }

    setPage(event: any) {
        this.helper.setPage(event, this);
    }
    
    selectedEvent(row:any) {
        this.selectedEventEmit.emit(row);
        this.modalService.dismissAll();
    }
}
