import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {environment} from "../../../../environments/environment";

/**
 * @description
 * Input Parameter
 * * columns : ==mandatory== object key {label:string, dataName:string, type:TYPE}. use for header table.
 * TYPE : common, number
 * * option : true/false , give option action row like view, delete, etc.
 *
 * Output Value Emitter :
 * * callFetchData => mandatory function return with mandatory value limit, offset.
 * * option => true/false , give option action row like view, delete, etc.
 * * edit
 * * delete
 * * selected
 *
 * Example Uses :
 * <ngx-datatable-cb #dt [columns]="columns" option="true" (callFetchData) = "fetchData($event)" ></ngx-datatable-cb>
*
*
**/
@Component({
    selector: 'ngx-datatable-cb',
    templateUrl: './ngx-datatable-card-body.html',
    styleUrls: ['./ngx-datatable-card-body.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class NgxDatatableCardBody{
    rows: any;
    offset: number = 0;
    loadingIndicator: boolean = false;
    count: number = 0;
    pageNum: number = 0;
    @Input() limit: number = 10;
    reorderable: boolean = true;
    recordsTotal: number = 0;
    @Input() pageSize: number = 10;
    @Input() inRow:boolean=false;
    @Input() columns: any[]=[
        {
            label:"test column",
            dataName:"testdata",
            type:"common",
        }
    ];
    @Input() option: any=false;
    /**
     * actionOption1
     * @Param option must be true
     * * default null
     * @Example
     * {
     *     class : delete, [pdf is red, primary is primary color, delete is red, edit is green]
     *     icon : icon-key see: https://angular.pixelstrap.com/cuba/icons/themify
     *     tooltip: description
     * }
     */
    @Input() actionOption1: any;
    @Input() hideDeleteButton:boolean=false;
    @Input() tooltipUpdateTxt:string='Update Data';

    @Output() callFetchData= new EventEmitter();
    @Output() edit= new EventEmitter();
    @Output() delete= new EventEmitter();
    @Output() selected= new EventEmitter();
    @Output() actionOption1Return= new EventEmitter();
    @Output() onBlurInputNumberEvent= new EventEmitter();
    @Output() onChangeInputNumberEvent= new EventEmitter();
    @Output() onBlurNoteEvent= new EventEmitter();

    setPage(event: any) {
        this.offset = event.offset * this.limit;
        this.limit = event.limit;
        this.callFetchData.emit({
            limit: this.limit,
            offset: this.offset
        });
        this.pageNum = event.offset;
    }
    resetPage(){
        this.offset = 0;
        this.limit = 10;
        this.pageNum = 0;
    }
    setLoadingDt(value:boolean){
        this.loadingIndicator = value;
    }

    setAllRows(rows:any[], recordsFiltered:number, recordsTotal:number ){
        const record = this.checkMaxPage(recordsFiltered, this.pageSize);
        this.rows =[];
        this.rows.push(...rows);
        this.count = record;
        this.recordsTotal = recordsTotal;
        this.loadingIndicator = false;
    }

    selectedEvent(row: any) {
        this.selected.emit(row);
    }

    private checkMaxPage(recordsTotal: any, pageSize: number) {
        const recordsTotalInt : number = parseInt(recordsTotal);
        const maxTotalPage : number = parseInt(environment.maxPageAll);
        const maxData = maxTotalPage * pageSize;
        if (recordsTotalInt > pageSize) {
            if (recordsTotalInt > maxData) {
                return maxData;
            }
        }

        return recordsTotalInt;
    }

    editEvent(row: any) {
        this.edit.emit(row);
    }

    deleteEvent(row: any) {
        this.delete.emit(row);
    }

    onSort(event: any) {
        console.log('Sort Event', event);
        this.loadingIndicator=true;
        // emulate a server request with a timeout
        setTimeout(() => {
            const rows = [...this.rows];
            // this is only for demo purposes, normally
            // your server would return the result for
            // you and you would just set the rows prop
            const sort = event.sorts[0];
            rows.sort((a, b) => {
                return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
            });

            this.rows = rows;
            this.loadingIndicator=true;
        }, 1000);
    }

    actionOption1Click(row: any) {
        this.actionOption1Return.emit(row);
    }

    onBlurInputNumber(event, row: any) {
        let ret = {
            newValue:event.target.value,
            row: row
        }
        this.onBlurInputNumberEvent.emit(ret);
    }

    onChangeInputNumber(event, row: any) {
        let ret = {
            newValue:event.target.value,
            row: row
        }
        this.onChangeInputNumberEvent.emit(ret);
    }

    onBlurNote(event, row: any) {
        let ret = {
            newValue:event.target.value,
            row: row
        }
        this.onBlurNoteEvent.emit(ret);
    }
}
