<app-breadcrumb [title]="'Today Summary'"
                [subTitle]="today"
                [items]="['today-summary']" [active_item]="'Today Summary'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-6">
            <fixed-table #sellingSumm
                         [headerListAndKey]="sellingSummHeader"
                         title="Total Penjualan"
                         subTitle="Rincian Operator"
                         [subSubTitle]="sellingSummAmount"
            ></fixed-table>
        </div>
        <div class="col-xl-6">
            <fixed-table #paymentMethod
                         [headerListAndKey]="paymentMethodHeader"
                         title="Pembayaran"
                         subTitle="Rincian Metode Pembayaran"
            ></fixed-table>
        </div>
    </div>
</div>
